import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
// import { Toast } from 'antd-mobile';
import { INVITE_CODE_BIND } from '@/config';
import { handleResponse } from '@/utils';

export const useBindInviteCodeApi = () => {
	const { UserInfoStore } = useRootStore();

	const bindInviteCode = ({ inviteCode }) => {
		const params = {
			inviteCode
		};
		return axios.post(INVITE_CODE_BIND, params, {
			headers: {
				token: UserInfoStore.token
			}
		});
	};

	const bindInviteCodeMutation = useMutation(bindInviteCode, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				UserInfoStore.setInviteCodeBindResult(data);
				return;
			}
		},
		onError: err => {
			UserInfoStore.setInviteCodeBindResult({ bindResult: 'fail' });
		},
		onSettled: () => {}
	});

	return bindInviteCodeMutation;
};
