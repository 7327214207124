/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '@/stores';
import './InviteOverlay.scss';

import invitePic from '@/imgs/pics/share-guide.png';
import customerServicePic from '@/imgs/pics/customer-service.png';

const InviteOverlay = () => {
	const { MineStore } = useRootStore();

	const picObj = {
		isInvite: invitePic,
		isCustomerService: customerServicePic
	};

	return (
		<>
			{MineStore.isOverLayShow ? (
				<div
					className="overlay"
					onClick={() => {
						MineStore.toggleOverLayShow();
					}}
				>
					<img src={picObj[MineStore.showedOverlayPic]} alt="invite-friend" />
				</div>
			) : null}
		</>
	);
};

InviteOverlay.displayName = 'InviteOverlay';

export default observer(InviteOverlay);
