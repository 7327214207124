/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { CHAT_CONTENT_PAGE } from '@/config';
import { handleResponse } from '@/utils';

// 【hook】拉取聊天内容
export const usePullChatContentApi = () => {
	const { ChatStore, UserInfoStore } = useRootStore();

	const pullChatContent = ({ sessionId = '', firstChatRecordId = '' }) => {
		const config = {
			params: {
				sessionId,
				firstChatRecordId
			},
			headers: {
				token: UserInfoStore.token
			}
		};

		return axios.get(CHAT_CONTENT_PAGE, config);
	};

	const pullChatContentMutation = useMutation(pullChatContent, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				const { list = [] } = data;
				const oldList = ChatStore.messages;
				const systemMessage = ChatStore.createSystemMessage();
				const placeHolderMessage = UserInfoStore.notRemindStatus === 1 ? [systemMessage] : [];
				const newList = oldList.length === 0 && list.length === 0 ? placeHolderMessage : [...list, ...oldList];
				// console.log('pullChatContent', data);
				ChatStore.setIsFirstTime(false);
				ChatStore.setMessages(newList);
				ChatStore.setPullContent(data);
				return;
			}
		},
		onError: err => {
			console.error('usePullChatContentApi', err);
		}
	});

	return pullChatContentMutation;
};
