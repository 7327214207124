/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
// import cx from 'classnames';
import { Switch } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useRootStore } from '@/stores';
import './SettingBlock.scss';

// 图片
import to from '@/imgs/icon/to.svg';

// API
import { useGetUserConfigApi, useSaveUserConfigStatusApi } from '@/api';

const SettingBlock = () => {
	const { UserInfoStore, MineStore } = useRootStore();
	const navigate = useNavigate();
	const getUserConfigMutation = useGetUserConfigApi();
	const saveUserConfigStatusMutation = useSaveUserConfigStatusApi();
	const { isLoading: isGetUserConfigLoading } = getUserConfigMutation;
	const { isLoading: isSaveUserConfigStatusLoading } = saveUserConfigStatusMutation;

	const switchStyle = {
		'--checked-color': '#000000',
		'--height': '0.48rem',
		'--width': '0.8rem'
	};

	useEffect(() => {
		UserInfoStore.token && getUserConfigMutation.mutate();
	}, [UserInfoStore.token]);

	const toggleUserConfig = (type, targetStatus) => {
		const params = {
			type: type,
			status: targetStatus
		};
		UserInfoStore.token && saveUserConfigStatusMutation.mutate(params);
	};

	return (
		<ul className={'mine-setting-block'}>
			{UserInfoStore.userConfig.userConfigVOList &&
				UserInfoStore.userConfig.userConfigVOList.map(configListitem => {
					return (
						<li className={'mine-setting-block-item'} key={configListitem?.id}>
							<div className="left-title">{configListitem?.configName ?? ''}</div>
							<Switch
								checked={configListitem?.status === 1}
								style={switchStyle}
								loading={isSaveUserConfigStatusLoading}
								onChange={val => {
									toggleUserConfig(configListitem?.type, val ? 1 : 0);
								}}
							/>
						</li>
					);
				})}

			<li
				className={'mine-setting-block-item general-setting'}
				onClick={() => {
					navigate('/privacy-agreement');
				}}
			>
				<div className="left-title">{'隐私协议'}</div>
				<img src={to} alt="" />
			</li>

			<li
				className={'mine-setting-block-item general-setting'}
				onClick={() => {
					MineStore.setShowedOverlayPic('isCustomerService');
					MineStore.toggleOverLayShow();
				}}
			>
				<div className="left-title">{'客服'}</div>
				<img src={to} alt="" />
			</li>

			<li
				className={'mine-setting-block-item general-setting'}
				onClick={() => {
					navigate('/about-us');
				}}
			>
				<div className="left-title">{'关于我们'}</div>
				<div>
					<span className={'semantic-version'}>{'v 0.0.1'}</span>
					<img src={to} alt="" />
				</div>
			</li>
		</ul>
	);
};

export default observer(SettingBlock);
