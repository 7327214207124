import { makeAutoObservable } from 'mobx';

export class UserInfoStore {
	isLogin = false;
	isUserInfoBlockLoading = false;
	userInfoData = {};
	userConfig = {};
	inviteCode = '';
	inviteCodeBindResult = 'fail';

	constructor(rootStore) {
		makeAutoObservable(this, {
			rootStore: false
		});
		this.rootStore = rootStore;
	}

	/* =============== 计算属性 =============== */
	get userName() {
		return this.isLogin ? this.userInfoData?.nickname ?? '' : '未登录';
	}

	get cardServiceTitle() {
		if (!this.isLogin) {
			return '登录获取免费服务';
		}

		if (this.rootStore.ServiceCenterStore.isNonMember) {
			return '升级服务即可获得 50 次对话机会';
		}

		return this.rootStore.ServiceCenterStore.userVipConfig?.vipName ?? '--';
	}

	get privilegeName() {
		return this.rootStore.ServiceCenterStore.userVipConfig?.vipName ?? '--';
	}

	get cardBtnText() {
		if (!this.isLogin) {
			return '请登录';
		}

		return this.rootStore.ServiceCenterStore.isNonMember ? '升级' : '续费';
	}

	get userAvatar() {
		return this.isLogin ? this.userInfoData?.avatarUrl : '';
	}

	get token() {
		return this.userInfoData?.token ?? '';
	}

	get notRemindStatus() {
		const noRemindConfig = this.userConfig?.userConfigVOList?.find(item => item.configName === '引导语');
		const notRemindStatus = noRemindConfig ? noRemindConfig.status : 0;
		return notRemindStatus === 1;
	}

	get notShowCareerStatus() {
		const notShowCareerConfig = this.userConfig?.userConfigVOList?.find(item => item.configName === '角色选择');
		const notShowCareerStatus = notShowCareerConfig ? notShowCareerConfig.status : 0;
		return notShowCareerStatus === 0;
	}

	/* =============== 方法 =============== */
	// 设置是否登录
	setIsLogin(bool = false) {
		this.isLogin = bool;
	}

	// 设置是否用户信息块加载中
	setIsUserInfoBlockLoading(bool = false) {
		this.isUserInfoBlockLoading = bool;
	}

	// 设置用户信息
	setUserInfo(userInfoData = {}) {
		this.userInfoData = userInfoData;
	}

	// 设置用户配置
	setUserConfig(userConfig = {}) {
		this.userConfig = userConfig;
	}

	setInviteCode({ inviteCode = '' }) {
		this.inviteCode = inviteCode;
	}

	setInviteCodeBindResult({ bindResult = '' }) {
		this.inviteCodeBindResult = bindResult;
	}
}
