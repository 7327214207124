/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { useRootStore } from '@/stores';
import './ChatCareerPicker.scss';

// 子组件
// import LordIcon from '@/components/LordIcon/LordIcon.jsx';

import rotateCw from '@/imgs/icon/rotate_cw.svg';
import closeSmall from '@/imgs/icon/close_small.svg';

// API
import { useConfigCareersApi } from '@/api';

const ChatCareerPicker = () => {
	const { ChatStore, UserInfoStore } = useRootStore();
	const configCareersMutation = useConfigCareersApi();

	// const lordIconProps = {
	// 	trigger: 'click',
	// 	size: '0.24rem',
	// 	src: 'https://cdn.lordicon.com/xsdtfyne.json'
	// };

	useEffect(() => {
		UserInfoStore.token && configCareersMutation.mutate();
	}, [UserInfoStore.token]);

	const hideTheBar = () => {
		ChatStore.setIsCareerPickerShow(false);
	};

	const repickTheCareer = () => {
		ChatStore.setIsCareerPickerFold(false);
		ChatStore.setPickedCareer(undefined);
	};

	const clickTheListItem = role => {
		ChatStore.setIsCareerPickerFold(true);
		ChatStore.setPickedCareer(role);
	};

	const containerClassnames = cx('chat-career-picker-container', {
		notShow:
			!ChatStore.isCareerPickerShow ||
			ChatStore.isNoCareerList ||
			ChatStore.isInputFocus ||
			UserInfoStore.notShowCareerStatus
	});

	const rolePickerListClassnames = cx('role-picker-list', { isFold: ChatStore.isCareerPickerFold });

	const BarIcon = () => {
		return ChatStore.pickedCareer ? (
			<div
				onClick={() => {
					repickTheCareer();
				}}
				className="repick-career"
			>
				<img src={rotateCw} alt="" />
				<span className="repick-career-text">重新选择</span>
			</div>
		) : (
			<img
				onClick={() => {
					hideTheBar();
				}}
				src={closeSmall}
				alt=""
			/>
		);
	};

	return (
		<section className={containerClassnames}>
			{/* Bar */}
			<div className="picker-bar">
				<div className="picker-bar-text">{ChatStore.pickerBarTxt}</div>
				<div className={cx('picker-bar-icon')}>
					{/* <LordIcon {...lordIconProps} /> */}
					<BarIcon />
				</div>
			</div>
			{/* 角色选择框 */}
			<ul className={rolePickerListClassnames}>
				{ChatStore.roleList.map(role => {
					const isPicked = ChatStore?.pickedCareer?.id === role.id;
					const roleStyle = {
						backgroundImage: `url(${role.avatarUrl})`,
						backgroundSize: 'contain',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center center'
					};

					return (
						<li
							className={cx('role-picker-list-item', { picked: isPicked })}
							key={role.id}
							onClick={() => {
								clickTheListItem(role);
							}}
							style={roleStyle}
						></li>
					);
				})}
			</ul>
		</section>
	);
};

ChatCareerPicker.displayName = 'ChatCareerPicker';

export default observer(ChatCareerPicker);
