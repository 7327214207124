/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '@/stores';
import { Popover } from 'antd-mobile';
import './InviteBlock.scss';

// 图片
import alertIcon from '@/imgs/icon/alert.svg';
import inviteIcon from '@/imgs/icon/invite_icon.svg';
import plusIcon from '@/imgs/icon/plus.svg';

// api
import { useInviteeListApi } from '@/api';

const InviteBlock = () => {
	const { MineStore, UserInfoStore } = useRootStore();
	const [isPopoverShow, setIsPopoverShow] = useState(false);
	const getInviteeListMutation = useInviteeListApi();

	useEffect(() => {
		UserInfoStore.token && getInviteeListMutation.mutate();
	}, [UserInfoStore.token]);

	const toInviteFriend = event => {
		event.stopPropagation();
		setIsPopoverShow(false);
		MineStore.setShowedOverlayPic('isInvite');
		MineStore.toggleOverLayShow();
	};

	const switchPopoverShow = e => {
		e.preventDefault();
		e.stopPropagation();
		setIsPopoverShow(!isPopoverShow);
	};

	const shouldAddShow = MineStore.inviteeListData?.list?.length < MineStore.inviteeListData?.maxInviteUserCount;

	return (
		<div
			className="mine-invite-block"
			onClick={event => {
				toInviteFriend(event);
			}}
		>
			<div className="invite-title-and-invitee-list">
				<div className="invite-title">
					<span>{'邀请好友领7天会员'}</span>
					<Popover
						stopPropagation={['click']}
						content="每邀请一个新用户登录并发送一段对话就可以获得1天的基础会员，成功邀请5个用户后，则可额外获得2天会员"
						mode="dark"
						trigger="click"
						placement="top"
						visible={!MineStore.isInviteOverLayShow && isPopoverShow}
						style={{
							lineHeight: 1.5
						}}
					>
						<img
							onClick={e => {
								switchPopoverShow(e);
							}}
							src={alertIcon}
							alt=""
							style={{ fontSize: '0.3rem', marginLeft: '0.1rem' }}
						/>
					</Popover>
				</div>

				<div className="already-gained-days">
					<span>{`已领取：${MineStore.inviteeListData?.awardDaysCount ?? '--'} 天`}</span>
				</div>
			</div>

			{MineStore.inviteeListData?.list?.length === 0 ? (
				<div className="invite-button">
					<img src={inviteIcon} alt="" style={{ fontSize: '0.3rem', marginLeft: '0.1rem' }} />
					<span>{'邀请好友'}</span>
				</div>
			) : (
				<ul className="invitee-list">
					{MineStore.inviteeListData?.list?.map(item => {
						return (
							<li className="invitee-list-item" key={item.uid}>
								<img className="invitee-list-item-avatar" src={item.avatarUrl} alt="" />
							</li>
						);
					})}
					{shouldAddShow ? (
						<li className="invitee-list-item plus-btn">
							<img className="invitee-list-item-avatar" src={plusIcon} alt="" />
						</li>
					) : null}
				</ul>
			)}
		</div>
	);
};

InviteBlock.displayName = 'InviteBlock';

export default observer(InviteBlock);
