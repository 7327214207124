import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { VIP_PACKAGE_BY_TYPE } from '@/config';
import { handleResponse } from '@/utils';

export const useGetVipPackageByTypeApi = () => {
	const { ServiceCenterStore, UserInfoStore } = useRootStore();

	const getVipPackageByType = ({ vipTypeId }) => {
		const config = {
			params: {
				vipTypeId
			},
			headers: {
				token: UserInfoStore.token
			}
		};
		return axios.get(VIP_PACKAGE_BY_TYPE, config);
	};

	const getVipPackageByTypeMutation = useMutation(getVipPackageByType, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				// console.log('getVipPackageByTypeMutation', data);
				ServiceCenterStore.setUserVipPackage(data);

				setTimeout(() => {
					const theFirstPackage = data?.vipPackageList?.[0] ?? {};
					ServiceCenterStore.setPickedServicePackage(theFirstPackage);
				}, 20);
			}
		},
		onError: err => {
			console.log('useGetVipPackageByTypeApi', err);
		},
		onSettled: () => {}
	});

	return getVipPackageByTypeMutation;
};
