import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { useRootStore } from '@/stores';
import './NavBar.scss';

// 子组件
// import LordIcon from '../LordIcon/LordIcon.jsx';

const NavBar = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { NavBarStore, ChatStore } = useRootStore();
	const { navMenu } = NavBarStore;
	const currentRoute = location.pathname;

	const clickTheMenu = item => {
		if (item.path === currentRoute) return;
		if (item.path === 'chat' && ChatStore.curChat?.sessionId) {
			navigate(`chat/${ChatStore.curChat?.sessionId}`);
			return;
		}

		navigate(item.path);
	};

	// const lordIconProps = {
	// 	trigger: 'click',
	// 	size: '0.5rem'
	// };

	return (
		<div className="nav-bar">
			<ul className={cx('nav-bar-list', { disabled: !ChatStore.isTopBarClickable })}>
				{navMenu?.map(item => {
					const isCurPath = currentRoute.includes(`/${item.path}`);
					// const colors = {
					// 	primary: isCurPath ? '#5A64E8' : '',
					// 	secondary: isCurPath ? '#5A64E8' : ''
					// };

					const curClassname = cx('nav-bar-list-item', { actived: isCurPath });

					return (
						<li key={item.name} className={curClassname} onClick={() => clickTheMenu(item)}>
							{/* <LordIcon src={item.iconUrl} colors={colors} {...lordIconProps} /> */}
							<img
								className={cx('nav-bar-icon')}
								src={isCurPath ? item.imgActiveSrc : item.imgSrc}
								alt=""
							/>
							<div className="nav-bar-name">{item.name}</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

NavBar.displayName = 'NavBar';

export default observer(NavBar);
