/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { GET_USER_CONFIG } from '@/config';
import { handleResponse } from '@/utils';

export const useGetUserConfigApi = () => {
	const { ChatStore, UserInfoStore } = useRootStore();

	const getUserConfig = params => {
		const config = {
			params,
			headers: {
				token: UserInfoStore.token
			}
		};

		return axios.get(GET_USER_CONFIG, config);
	};

	const getUserConfigMutation = useMutation(getUserConfig, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				// console.log('userConfig', data);
				UserInfoStore.setUserConfig(data);
				return;
			}
		},
		onError: err => {
			console.error('useGetChatContentPageApi', err);
		}
	});

	return getUserConfigMutation;
};
