/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import './PrivacyAgreement.scss';

// 子组件
import { Markdown } from '@/components/MarkDown/MarkDown';

const PrivacyAgreement = () => {
	const content = `
  # 灵悉AI助手隐私保护指引

感谢您选择使用灵悉AI助手（下称“我们”）提供的服务。我们非常重视您的个人信息和隐私保护。在您使用我们的服务时，我们将按照本隐私保护指引（以下简称“本指引”）收集、使用、存储和共享您的个人信息。请您在使用我们的服务前，仔细阅读并充分理解本指引。

## 1. 个人信息的收集与使用

我们收集和使用您的个人信息，旨在向您提供更好的服务。以下是我们可能收集的个人信息：

### 1.1 注册与登录

在您注册或登录时，我们将获取您的微信授权信息（包括微信昵称、头像、性别和地区等），用于创建您的账户。

### 1.2 AI聊天对话服务

在您使用我们的AI聊天对话服务时，我们会收集并保存您在服务中产生的对话内容。您可以随时主动删除服务器上的内容。

### 1.3 免费服务和升级服务

您在使用我们的对话服务时，可以免费使用一定次数。若您需要更多服务，可以升级到【基础服务】或【高级服务】，按月、季度或年付费使用更高级的服务。

## 2. 信息的存储

我们会将您的个人信息存储在服务器中，以便您可以随时查看或修改。您的个人信息仅在法定期限内保留。

## 3. 信息安全

我们会采取合理的技术措施保障您的个人信息安全，防止您的个人信息泄露、损坏或丢失。

## 4. 信息共享

除法律法规要求或本指引另有规定外，未经您同意，我们不会将您的个人信息向任何第三方共享。

## 5. 您的权利

您有权随时查看、修改和删除您在我们服务器上的个人信息。您还可以随时取消您对我们服务的授权。

## 6. 变更

我们可能会对本指引进行更新。在本指引发生重大变更时，我们将在网站、应用程序或其他适当方式通知您。

## 7. 联系我们

如果您对本指引有任何疑问、意见或建议，请联系我们：469460857@qq.com

感谢您对灵悉AI助手的支持和信任！`;

	return (
		<section className="privacy-agreement-page">
			<div className="chat-content">
				<Markdown content={content} />
			</div>
		</section>
	);
};

PrivacyAgreement.displayName = 'PrivacyAgreement';

export default observer(PrivacyAgreement);
