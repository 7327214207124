/* eslint-disable no-unused-vars */
import { makeAutoObservable } from 'mobx';
import { message } from 'antd';
import { isTest, TEST_URL, PROD_URL, ORDER_STATUS } from '@/config';
import axios from 'axios';
// import LocalForageHelper from '@/utils/storageHelper';

export class WeChatStore {
	authDataFromWeChat = {};
	jsApiTicket = '';
	WxConfig = {};

	constructor(rootStore) {
		makeAutoObservable(this, {
			rootStore: false
		});
		this.rootStore = rootStore;
	}

	/* =============================== 计算属性 ================================ */

	/* =============================== 方法 ================================ */
	// 异步加载微信JS-SDK
	handleLoadScript(callback) {
		const src = `http://res2.wx.qq.com/open/js/jweixin-1.6.0.js`;
		if (!(typeof callback === 'function')) {
			callback = function () {};
		}
		var check = document.querySelectorAll(`script[src="${src}"]`);
		if (check.length > 0) {
			check[0].addEventListener('load', function () {
				callback();
			});
			callback();
			return;
		}
		var script = document.createElement('script');
		var head = document.getElementsByTagName('head')[0];
		script.type = 'text/javascript';
		script.charset = 'UTF-8';
		script.src = src;
		if (script.addEventListener) {
			script.addEventListener(
				'load',
				function () {
					callback();
				},
				false
			);
		} else if (script.attachEvent) {
			script.attachEvent('onreadystatechange', function () {
				if (window.event) {
					let target = window.event.srcElement;
					if (target && target.readyState === 'loaded') {
						callback();
					}
				}
			});
		}
		head.appendChild(script);
	}

	// 生成配置
	setWxConfig(WxConfig = {}) {
		this.WxConfig = WxConfig;
	}

	async handleWxConfig() {
		const fullConfig = this.WxConfig;
		delete fullConfig.url;
		const config = {
			...fullConfig,
			jsApiList: ['chooseWXPay', 'updateAppMessageShareData', 'updateTimelineShareData', 'showMenuItems'],
			debug: false
		};
		console.log('wxConfig', config);
		window.wx && window.wx.config(config);
	}

	async handleShareJsApi(options) {
		await this.handleWxConfig();

		window.wx.ready(() => {
			let checkJsApiRes = {};

			window.wx.checkJsApi({
				jsApiList: ['chooseWXPay', 'updateAppMessageShareData', 'updateTimelineShareData', 'showMenuItems'],
				success: res => {
					console.log('checkJsApi', res);

					checkJsApiRes = res;
				},
				fail: err => {
					console.log('checkJsApi err', err);
				}
			});

			// window.wx.showMenuItems({
			// 	menuList: [
			// 		'menuItem:copyUrl',
			// 		'menuItem:share:appMessage',
			// 		'menuItem:share:timeline',
			// 		'menuItem:share:qq',
			// 		'menuItem:favorite',
			// 		'menuItem:share:QZone',
			// 		'menuItem:profile'
			// 	], // 要显示的菜单项，所有menu项见附录3
			// 	success: () => {
			// 		console.log('showMenuItem yes');
			// 	},
			// 	fail: res => {
			// 		console.log('showMenuItem no', res);
			// 	}
			// });

			window.wx.updateAppMessageShareData({
				...options,
				success: res => {
					console.log('设置分享成功', res);
				},
				fail: err => {
					console.log('设置分享失败', err);
				}
			});

			window.wx.updateTimelineShareData({
				...options,
				success: res => {
					console.log('设置PYQ分享成功', res);
				},
				fail: err => {
					console.log('设置PYQ分享失败', err);
				}
			});

			window.wx.error(err => {
				console.log('wxError', err);
			});
		});
	}

	// 设置获得的微信授权信息
	setAuthDataFromWeChat(authDataFromWeChat = {}) {
		this.authDataFromWeChat = authDataFromWeChat;
	}

	// 微信登录
	async wxLogin() {
		const inviteCode = this.rootStore.URLParamsStore.getInviteCode();
		// const inviteCodeFromLocalStorage = await LocalForageHelper.get('inviteCode');
		// console.log('inviteCode', [inviteCode]);

		const appId = this.rootStore.ChatStore.globalConfig.wxAppId;
		const redirectURL = isTest ? `${TEST_URL}?inviteCode=${inviteCode}` : `${PROD_URL}?inviteCode=${inviteCode}`;
		// const redirectURL = isTest ? `${TEST_URL}` : `${PROD_URL}`;
		const redirectUri = encodeURIComponent(redirectURL); // 替换为您的重定向URI
		const state = 'Redirect'; // 可以设置一个状态值，用于防止CSRF攻击
		const responseType = 'code';
		const scope = 'snsapi_userinfo';

		const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&state=${state}#wechat_redirect`;

		window.location.href = url;
	}

	// 支付
	toPay({ timeStamp, nonceStr, packageValue, signType, paySign, orderNo }) {
		window.wx &&
			window.wx.chooseWXPay({
				timestamp: timeStamp, // 时间戳，与上面的配置信息中的时间戳相同
				nonceStr, // 随机字符串，与上面的配置信息中的随机字符串相同
				package: packageValue, // 统一下单接口返回的 prepay_id 参数值，格式为 prepay_id=xxx
				signType, // 签名类型，默认为MD5
				paySign, // 签名，具体生成方法可参考微信支付API文档
				success: res => {
					// 支付成功后的处理逻辑
					console.log('支付成功', res);
					this.checkPaymentStatus(orderNo, 5);
				},
				cancel: res => {
					// 取消支付后的处理逻辑
					console.log('支付取消', res);
				},
				fail: res => {
					// 支付失败后的处理逻辑
					console.log('支付失败', res);
				}
			});
	}

	async checkPaymentStatus(orderNo, maxAttempts = 5) {
		let attempts = 0;
		while (attempts < maxAttempts) {
			try {
				const config = {
					params: {
						orderNo
					},
					headers: {
						token: this.rootStore.UserInfoStore.token
					}
				};

				const response = await axios.get(ORDER_STATUS, config);
				const data = response.data.data;
				if (data.status === 3) {
					console.log('Payment successful', data);
					// 进行支付成功后的操作
					message.success({
						key: 'pay-success',
						content: '支付成功',
						duration: 1.5,
						style: {
							marginTop: '1.3rem'
						}
					});

					this.rootStore.ServiceCenterStore.toggleUserVipInfoTrigger();
					return;
				} else {
					console.log('Payment not successful', data);
					message.error({
						key: 'pay-fail',
						content: '支付失败',
						duration: 1.5,
						style: {
							marginTop: '1.3rem'
						}
					});
				}
			} catch (error) {
				console.error(error);
			}
			attempts++;
			await new Promise(resolve => setTimeout(resolve, 1000));
		}
	}
}
