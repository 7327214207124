import { message as antdMessage } from 'antd';

export const handleResponse = (resData, successCallback, errorCallback) => {
	const { data = {}, code, message } = resData?.data;

	if (code === 500) {
		antdMessage.error({
			key: 'handle_response_500_words',
			content: message,
			duration: 1.5,
			style: {
				marginTop: '1.3rem'
			}
		});
		errorCallback && errorCallback();
		return;
	}

	if (code === 401) {
		// 401 表示未授权，跳转到首页
		window.location.href = '/chat';
		return;
	}

	if (code === 200) {
		successCallback && successCallback(data);
		return 'success';
	} else {
		antdMessage.error({
			key: 'handle_response_error_words',
			content: message,
			duration: 1.5,
			style: {
				marginTop: '1.3rem'
			}
		});
		errorCallback && errorCallback();
	}

	successCallback && successCallback(data);
};
