/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { USER_INFO } from '@/config';
import { handleResponse } from '@/utils';

// 【hook】查询用户信息
export const useUserInfoApi = () => {
	const { UserInfoStore } = useRootStore();

	const userInfo = ({ openId = '' }) => {
		const config = {
			params: {
				openId
			},
			headers: {}
		};
		return axios.get(USER_INFO, config);
	};

	const userInfoMutation = useMutation(userInfo, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				UserInfoStore.setIsLogin(true);
				UserInfoStore.setUserInfo(data);
			} else {
				UserInfoStore.setIsLogin(false);
				UserInfoStore.setUserInfo({});
			}
		},
		onError: err => {
			console.error('useUserInfoApi', err);
			UserInfoStore.setUserInfo({});
			UserInfoStore.setIsLogin(false);
		}
	});

	return userInfoMutation;
};
