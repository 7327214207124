import { makeAutoObservable } from 'mobx';

// 图片
import ChatGPT_Avatar from '@/imgs/icon/avatar_gpt.svg';

export class ChatStore {
	/* =============================== 状态 ================================ */
	topBarTitle = ``;
	isTopBarClickable = true;

	inputPlaceholder = '在这里输入你的问题';
	inputValue = ``;
	isInputFocus = false;
	isInputDisabled = false;

	isCareerPickerFold = false;
	isCareerPickerShow = true;
	roleList = [];
	isLoadingCareerList = false;
	pickedCareer = undefined;

	chatSideLayerTitle = '对话记录';
	isSideLayerShow = false;
	curChat = {};
	isLoadingChatList = false;
	chatList = [];
	chatListTrigger = false;
	encryptDigest = '';

	isFreeTimesUsedUp = false;
	freeTimesUsedUpTitleText = '免费次数已用尽，升级服务后恢复对话';
	messages = []; // 单独聊天的对话内容
	isFirstTime = true;
	pullContent = {
		firstChatRecordId: '',
		hasMore: 0,
		list: []
	}; // 单独聊天拉取内容

	globalConfig = {};

	scrollToBottomTrigger = false;

	constructor(rootStore) {
		makeAutoObservable(this, {
			rootStore: false
		});
		this.rootStore = rootStore;
	}

	/* =============================== 计算属性 ================================ */
	get createNewChatBtnTxt() {
		return this.rootStore.UserInfoStore.isLogin ? '创建新对话' : '请登录';
	}

	get isNoInputValue() {
		return this.inputValue.length === 0;
	}

	get pickerBarTxt() {
		return this.pickedCareer ? `已选角色：${this.pickedCareer?.name ?? ''}` : '请选择智能助手所扮演的角色';
	}

	get chatCount() {
		return this.chatList.length;
	}

	get isNoCareerList() {
		return this.roleList.length === 0;
	}

	get curMessageNum() {
		return this.messages.length;
	}

	get isNoMessage() {
		return this.messages.length === 0;
	}

	get shouldPullMoreContent() {
		return this.pullContent.hasMore === 1;
	}

	/* =============================== 方法 ================================ */
	setGlobalConfig(newConfig) {
		this.globalConfig = newConfig;
	}

	toggleScrollToBottomTrigger() {
		this.scrollToBottomTrigger = !this.scrollToBottomTrigger;
	}

	// 顶 Bar
	setTopBarTitle(newTitle) {
		this.topBarTitle = newTitle;
	}

	setIsTopBarClickable(bool = true) {
		this.isTopBarClickable = bool;
	}

	// 输入框
	setInputPlaceholder(newPlaceholder) {
		this.inputPlaceholder = newPlaceholder;
	}

	setInputValue(newValue = '') {
		this.inputValue = newValue;
	}

	setInputFocus(bool = false) {
		this.isInputFocus = bool;
	}

	setInputDisabled(bool = false) {
		this.isInputDisabled = bool;
	}

	// 角色选择
	setIsCareerPickerShow(bool) {
		this.isCareerPickerShow = bool;
	}

	setIsLoadingChatList(bool) {
		this.isLoadingChatList = bool;
	}

	setChatList(newChatList = []) {
		this.chatList = newChatList;
	}

	toggleChatListTrigger() {
		this.chatListTrigger = !this.chatListTrigger;
	}

	setCurChat(chat) {
		this.curChat = chat;
	}

	setIsCareerPickerFold(bool) {
		this.isCareerPickerFold = bool;
	}

	toggleFold() {
		this.isCareerPickerFold = !this.isCareerPickerFold;
	}

	setEncryptDigest(digest = '') {
		this.encryptDigest = digest;
	}

	setPickedCareer(role) {
		this.pickedCareer = role;
	}

	setCareerList(newCareerList) {
		this.roleList = newCareerList;
	}

	// 侧边弹出栏
	setIsSideLayerShow(bool = false) {
		this.isSideLayerShow = bool;
	}

	// 对话
	setIsFirstTime(bool = true) {
		this.isFirstTime = bool;
	}

	setIsFreeTimesUsedUp(bool = false) {
		this.isFreeTimesUsedUp = bool;
	}

	setMessages(messages = []) {
		this.messages = messages;
	}

	getMessages() {
		return this.messages;
	}

	createFakeAssistantMessage(content = '') {
		const newFakeAssistantMessage = {
			role: 'assistant',
			content,
			chatRecordId: Date.now() + 100,
			ctime: new Date().toLocaleString().replace(/\//g, '-'),
			sessionId: this.curChat.sessionId,
			avatarUrl: ChatGPT_Avatar,
			isLoading: true,
			isNew: true
		};

		return newFakeAssistantMessage;
	}

	addFakeAssistantMessage() {
		const fakeAssistantMessage = this.createFakeAssistantMessage();
		this.setMessages([...this.messages, fakeAssistantMessage]);
	}

	createSystemMessage() {
		const newMessage = {
			role: 'system',
			content:
				'Hi 主人，我是你的私人助理，我是一个非常智能的机器人，你可以提出想问的问题，我可以帮助你解决生活中的、工作中的、放松休息中的所有问题，快来和我聊天吧！',
			chatRecordId: Date.now() + 200,
			ctime: new Date().toLocaleString().replace(/\//g, '-'),
			sessionId: this.curChat.sessionId,
			avatarUrl: ChatGPT_Avatar
		};

		return newMessage;
	}

	createFakeUserMessage() {
		const message = {
			sessionId: this.curChat.sessionId,
			chatRecordId: Date.now(),
			role: 'user',
			avatarUrl: this.rootStore.UserInfoStore.userInfoData.avatarUrl,
			content: this.inputValue,
			ctime: new Date().toLocaleString().replace(/\//g, '-'),
			type: 1
		};

		return message;
	}

	addFakeUserMessage() {
		const fakeUserMessage = this.createFakeUserMessage();
		this.setMessages([...this.messages, fakeUserMessage]);
	}

	updateMessage(chatRecordId, content = '') {
		const messageToUpdate = this.messages.find(message => message.chatRecordId === chatRecordId);
		if (messageToUpdate) {
			messageToUpdate.content = content;
		}
	}

	getMessageContent(chatRecordId) {
		if (!chatRecordId) {
			return '';
		}
		const requiredMessage = this.messages.find(message => message.chatRecordId === chatRecordId);
		return requiredMessage?.content ?? '';
	}

	getLastChat() {
		return this.chatList[this.chatList.length - 1];
	}

	getTheLatestMessage() {
		return this.messages[this.messages.length - 1];
	}

	setPullContent(data = {}) {
		this.pullContent = data;
	}
}
