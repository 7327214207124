import { createBrowserRouter } from 'react-router-dom';
// import { Provider as KeepAliveProvider } from 'react-keep-alive';
import { store } from '@/stores/index.js';

// 组件
import ErrorPage from '@/pages/ErrorPage/error-page.jsx';

// 路由组件
import RedirectToChat from '@/redirectToChat.jsx';
import Home from '@/pages/Home/Home';
import Chat from '@/pages/Chat/Chat';
// import ChatIndex from '@/pages/Chat/widgets/ChatIndex/ChatIndex.jsx';
import ChatMainArea from '@/pages/Chat/widgets/ChatMainArea/ChatMainArea.jsx';
// import Application from '@/pages/Application/Application';
// import Write from '@/pages/Write/Write';
import Mine from '@/pages/Mine/Mine';
import ServiceCenter from '@/pages/ServiceCenter/ServiceCenter';
import PrivacyAgreement from '@/pages/PrivacyAgreement/PrivacyAgreement';
import AboutUs from '@/pages/AboutUs/AboutUs';

// imgIcon
import chatgptIcon from '@/imgs/icon/tab_chatgpt-grey.svg';
import chatgptIconActive from '@/imgs/icon/tab_chatgpt-black.svg';
// import appIcon from '@/imgs/icon/tab_application-grey.svg';
// import appIconActive from '@/imgs/icon/tab_application-black.svg';
// import writeIcon from '@/imgs/icon/tab_write-grey.svg';
// import writeIconActive from '@/imgs/icon/tab_write-black.svg';
import meIcon from '@/imgs/icon/tab_me-grey.svg';
import meIconActive from '@/imgs/icon/tab_me-black.svg';

const childrenRoute = [
	{
		path: 'chat',
		element: <Chat />,
		name: '聊天',
		iconUrl: 'https://cdn.lordicon.com/hpivxauj.json',
		imgSrc: chatgptIcon,
		imgActiveSrc: chatgptIconActive,
		children: [
			// {
			// 	index: true,
			// 	element: <ChatIndex />
			// },
			{
				path: ':sessionId',
				element: <ChatMainArea />
			}
		]
	},
	// {
	// 	path: 'application',
	// 	element: <Application />,
	// 	name: '应用',
	// 	iconUrl: 'https://cdn.lordicon.com/svbmmyue.json',
	// 	imgSrc: appIcon,
	// 	imgActiveSrc: appIconActive
	// },
	// {
	// 	path: 'write',
	// 	element: <Write />,
	// 	name: '写作',
	// 	iconUrl: 'https://cdn.lordicon.com/vufjamqa.json',
	// 	imgSrc: writeIcon,
	// 	imgActiveSrc: writeIconActive
	// },
	{
		path: 'mine',
		element: <Mine />,
		name: '我的',
		iconUrl: 'https://cdn.lordicon.com/bhfjfgqz.json',
		imgSrc: meIcon,
		imgActiveSrc: meIconActive
	}
];

store.NavBarStore.setNavMenu(childrenRoute);

const subRouterConfig = [
	{ index: true, element: <RedirectToChat /> },
	...childrenRoute,
	{
		path: '*',
		element: <RedirectToChat />
	}
];

const routerConfig = [
	{
		path: '/',
		element: <Home />,
		errorElement: <ErrorPage />,
		children: [
			{
				errorElement: <ErrorPage />,
				children: subRouterConfig
			}
		]
	},
	{
		path: 'service-center',
		element: <ServiceCenter />,
		errorElement: <ErrorPage />
	},
	{
		path: 'privacy-agreement',
		element: <PrivacyAgreement />,
		errorElement: <ErrorPage />
	},
	{
		path: 'about-us',
		element: <AboutUs />,
		errorElement: <ErrorPage />
	}
];

const router = createBrowserRouter(routerConfig);

export default router;
