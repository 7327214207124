import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { USER_VIP_INFO } from '@/config';
import { handleResponse } from '@/utils';

export const useGetUserVipInfoApi = () => {
	const { ServiceCenterStore, UserInfoStore } = useRootStore();

	const getUserVipInfo = params => {
		const config = {
			params,
			headers: {
				token: UserInfoStore.token
			}
		};

		return axios.get(USER_VIP_INFO, config);
	};

	const getUserVipInfoMutation = useMutation(getUserVipInfo, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				// console.log('getUserVipInfoMutation', data);
				ServiceCenterStore.setUserVipConfig(data);
				ServiceCenterStore.setCurrentService(data);
			}
		},
		onError: err => {
			console.log('useGetUserVipInfoApi', err);
		},
		onSettled: () => {}
	});

	return getUserVipInfoMutation;
};
