import './LoadingPage.scss';

const LoadingPage = () => {
	return (
		<div className="global-loading-spinner-container">
			<div className="global-loading-spinner"></div>
		</div>
	);
};

export default LoadingPage;
