import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '@/stores';
import './GlobalCurtain.scss';

const GlobalCurtain = () => {
	const { GlobalCurtainStore, ChatStore } = useRootStore();

	const clickTheCurtain = () => {
		GlobalCurtainStore.setIsGlobalCurtainShow(false);
		ChatStore.setIsSideLayerShow(false);
	};

	return (
		<div
			className="global-bg"
			onClick={() => {
				clickTheCurtain();
			}}
		></div>
	);
};

GlobalCurtain.displayName = 'GlobalCurtain';

export default observer(GlobalCurtain);
