/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import './AboutUs.scss';

// 子组件
import { Markdown } from '@/components/MarkDown/MarkDown';

const AboutUs = () => {
	const content = `
  # 关于我们

  灵悉AI助手（又名 Robochat ）是一款提供AI聊天对话服务的产品。我们的使命是为用户提供智能、便捷的沟通工具，帮助解决各种问题，提高工作效率，丰富娱乐生活。
  
  我们的团队由一群热衷于人工智能、机器学习和自然语言处理技术的专家组成。我们致力于不断优化和改进我们的产品，为用户提供更高质量的服务。
  
  ## 我们的产品
  
  灵悉AI助手为用户提供丰富的聊天功能，包括：
  
  - 实时聊天
  - 智能问答
  - 个性化推荐
  - 任务提醒与管理
  
  此外，我们还不断开发新功能，以满足用户不断变化的需求。
  
  ## 联系我们
  
  如果您有任何问题、建议或合作意向，请随时通过以下方式联系我们：
  
  - 电子邮箱：469460857@qq.com
  - 电话：17623816296
  - 地址：北京灵悉未来网络科技有限公司
  `;

	return (
		<section className="about-us-page">
			<div className="chat-content">
				<Markdown content={content} />
			</div>
		</section>
	);
};

AboutUs.displayName = 'AboutUs';

export default observer(AboutUs);
