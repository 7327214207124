import { makeAutoObservable } from 'mobx';

export class MineStore {
	/* =============================== 状态 ================================ */
	inviteeListData = {};
	isOverLayShow = false;
	showedOverlayPic = '';

	constructor(rootStore) {
		makeAutoObservable(this, {
			rootStore: false
		});
		this.rootStore = rootStore;
	}

	/* =============================== 计算属性 ================================ */

	/* =============================== 动作 ================================ */
	setInviteeListData(inviteeListData = {}) {
		this.inviteeListData = inviteeListData;
	}

	setShowedOverlayPic(pic = '') {
		this.showedOverlayPic = pic;
	}

	toggleOverLayShow() {
		this.isOverLayShow = !this.isOverLayShow;
	}

	logout() {
		console.log('logout');
	}
}
