/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { USER_AUTH } from '@/config';
import { handleResponse } from '@/utils';

// 【hook】用户授权
export const useUserAuthApi = () => {
	const { UserInfoStore, WeChatStore } = useRootStore();

	const userAuth = ({ code }) => {
		const params = {
			code
		};
		return axios.post(USER_AUTH, params, {
			headers: {
				token: UserInfoStore.token
			}
		});
	};

	const userAuthMutation = useMutation(userAuth, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				// console.log('userAuthFromWeChat', data);
				WeChatStore.setAuthDataFromWeChat(data);
				return;
			}
		},
		onError: err => {
			console.error('useUserAuthApi', err);
		}
	});

	return userAuthMutation;
};
