/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { message as antdMessage } from 'antd';
import { useRootStore } from '@/stores';
import { SESSION_CREATE } from '@/config';
import { handleResponse } from '@/utils';

// 【hook】创建会话
export const useSessionCreateApi = () => {
	const { ChatStore, UserInfoStore, GlobalCurtainStore } = useRootStore();
	const navigate = useNavigate();

	const isCareerPickerFoldOrHide =
		ChatStore.isCareerPickerFold || !ChatStore.isCareerPickerShow || ChatStore.isInputFocus;

	const sessionCreate = params => {
		return axios.post(SESSION_CREATE, params, {
			headers: {
				token: UserInfoStore.token
			}
		});
	};

	const sessionCreateMutation = useMutation(sessionCreate, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				const { list, sessionId } = data;
				const curChat = list.find(chat => chat.sessionId === sessionId);
				ChatStore.setCurChat(curChat);
				ChatStore.setChatList(list);
				ChatStore.setMessages([]);
				ChatStore.setIsCareerPickerFold(false);
				ChatStore.setPickedCareer(undefined);
				ChatStore.setTopBarTitle(curChat.title);
				ChatStore.setIsSideLayerShow(false);
				GlobalCurtainStore.setIsGlobalCurtainShow(false);

				const navUrl = `/chat/${sessionId}`;
				navigate(navUrl);

				antdMessage.info({
					key: 'create-session-success',
					content: '创建了一个新对话',
					duration: 1.5,
					style: {
						marginTop: isCareerPickerFoldOrHide ? '1.3rem' : '2.8rem'
					}
				});

				return;
			}
		},
		onError: err => {
			console.error('useSessionCreateApi', err);
		}
	});

	return sessionCreateMutation;
};
