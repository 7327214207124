/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
// import cx from 'classnames';
import { useRootStore } from '@/stores';
import './ServiceDesc.scss';

// 图片
import SilverBadge from '@/imgs/pics/badge_silver.png';
import GoldBadge from '@/imgs/pics/badge_gold.png';

const ServiceDesc = props => {
	const { ServiceCenterStore } = useRootStore();

	const vipTypeCode = ServiceCenterStore.userVipConfig?.vipTypeCode;
	const badgeSrc = vipTypeCode === 'MEMBER' ? SilverBadge : GoldBadge;

	return (
		<div className="current-service-desc">
			<div className="service-title-block">
				{vipTypeCode !== 'NON_MEMBER' ? <img className="service-badge" src={badgeSrc} alt="" /> : null}
				<div className="service-name">{ServiceCenterStore.userVipConfig?.vipName ?? '正在加载...'}</div>
			</div>

			<div
				className="member-duration"
				dangerouslySetInnerHTML={{ __html: ServiceCenterStore.memberTimeTextInServiceCenter }}
			></div>

			<div className="current-used-plan">
				<div className="cur-used-plan-text">
					{vipTypeCode === 'NON_MEMBER' ? (
						ServiceCenterStore.userVipConfig?.vipTypeDetail?.join(' | ')
					) : (
						<div>
							当前使用
							<span className="orange-text">{ServiceCenterStore.userVipConfig?.vipName ?? '--'}</span>方案
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default observer(ServiceDesc);
