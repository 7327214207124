/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { CONFIG_GLOBAL } from '@/config';
import { handleResponse } from '@/utils';

// 【hook】全局配置
export const useConfigGlobalApi = () => {
	const { ChatStore, UserInfoStore } = useRootStore();

	const configGlobal = () => {
		const config = {
			params: {},
			headers: {
				token: UserInfoStore.token
			}
		};

		return axios.get(CONFIG_GLOBAL, config);
	};

	const configGlobalMutation = useMutation(configGlobal, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				console.log('global config', data);
				ChatStore.setGlobalConfig(data);
				return;
			}
		},
		onError: err => {
			console.error('useConfigGlobalApi', err);
		}
	});

	return configGlobalMutation;
};
