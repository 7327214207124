/* eslint-disable no-unused-vars */
import { useRef } from 'react';
import ReactMarkdown from 'react-markdown';
// import sanitizeHtml from 'sanitize-html';
import RemarkMath from 'remark-math';
import RemarkBreaks from 'remark-breaks';
import RehypeKatex from 'rehype-katex';
import RemarkGfm from 'remark-gfm';
import RehypeHighlight from 'rehype-highlight';
import { copyToClipboard } from '@/utils';
import 'katex/dist/katex.min.css';

// 子组件
import LoadingDots from '@/components/LoadingDots/LoadingDots';

export const PreCode = props => {
	const ref = useRef(null);

	return (
		<pre ref={ref}>
			<span
				className="copy-code-button"
				onClick={() => {
					if (ref.current) {
						const code = ref.current.innerText;
						copyToClipboard(code);
					}
				}}
			></span>
			{props.children}
		</pre>
	);
};

export const Markdown = props => {
	// const safeMarkdownContent = sanitizeHtml(props.content, {
	// 	allowedTags: [
	// 		'p',
	// 		'a',
	// 		'strong',
	// 		'em',
	// 		'u',
	// 		'del',
	// 		'li',
	// 		'ul',
	// 		'ol',
	// 		'h1',
	// 		'h2',
	// 		'h3',
	// 		'h4',
	// 		'h5',
	// 		'h6',
	// 		'code',
	// 		'pre'
	// 	],
	// 	allowedAttributes: {
	// 		a: ['href', 'title']
	// 	},
	// 	allowedSchemes: ['http', 'https', 'mailto'],
	// 	disallowedTagsMode: 'escape',
	// 	allowedClasses: { '*': ['highlight'] },
	// 	transformTags: {
	// 		pre: sanitizeHtml.simpleTransform('pre', { class: 'highlight' }),
	// 		code: sanitizeHtml.simpleTransform('code', { class: 'highlight' })
	// 	}
	// });

	const shouldLoading = props.isLoading || props.content.length === 0;

	return (
		<>
			{shouldLoading ? (
				<LoadingDots />
			) : (
				<ReactMarkdown
					remarkPlugins={[RemarkMath, RemarkGfm, RemarkBreaks]}
					rehypePlugins={[
						RehypeKatex,
						[
							RehypeHighlight,
							{
								detect: false,
								ignoreMissing: true
							}
						]
					]}
					components={{
						pre: PreCode
					}}
					linkTarget={'_blank'}
				>
					{props.content}
				</ReactMarkdown>
			)}
		</>
	);
};
