/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { SAVE_USER_CONFIG_STATUS } from '@/config';
import { handleResponse } from '@/utils';

export const useSaveUserConfigStatusApi = () => {
	const { ChatStore, UserInfoStore } = useRootStore();

	const saveUserConfigStatus = params => {
		return axios.post(SAVE_USER_CONFIG_STATUS, params, {
			headers: {
				token: UserInfoStore.token
			}
		});
	};

	const saveUserConfigMutation = useMutation(saveUserConfigStatus, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				UserInfoStore.setUserConfig(data);
				return;
			}
		},
		onError: err => {
			console.error('useSaveUserConfigStatusApi', err);
		}
	});

	return saveUserConfigMutation;
};
