import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { VIP_TYPE_LIST } from '@/config';
import { handleResponse } from '@/utils';

export const useGetVipTypeListApi = () => {
	const { ServiceCenterStore, UserInfoStore } = useRootStore();

	const getVipTypeList = params => {
		const config = {
			params,
			headers: {
				token: UserInfoStore.token
			}
		};
		return axios.get(VIP_TYPE_LIST, config);
	};

	const getVipTypeListMutation = useMutation(getVipTypeList, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				// console.log('getVipTypeListMutation', data);
				const { vipTypeList = [] } = data;
				ServiceCenterStore.setVipTypeList(vipTypeList);
			}
		},
		onError: err => {
			console.log('useGetVipTypeListApi', err);
		},
		onSettled: () => {}
	});

	return getVipTypeListMutation;
};
