/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useLayoutEffect, useRef } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { useRootStore } from '@/stores';
import './ChatTopBar.scss';

// 子组件
// import LordIcon from '@/components/LordIcon/LordIcon.jsx';
import MenuIcon from '@/imgs/icon/button_menu.svg';
import NewPlus2 from '@/imgs/icon/new_plus2.svg';

// API
import { useSessionCreateApi } from '@/api';

const ChatTopBar = () => {
	const { GlobalCurtainStore, ChatStore, UserInfoStore } = useRootStore();
	const createUserSessionMutation = useSessionCreateApi();
	const divRef = useRef(null);

	// const lordIconProps = {
	// 	trigger: 'click',
	// 	size: '0.5rem'
	// };

	const clickMenu = () => {
		if (!ChatStore.isTopBarClickable) return;
		GlobalCurtainStore.setIsGlobalCurtainShow(true);
		ChatStore.setIsSideLayerShow(true);
	};

	const clickAdd = () => {
		if (!ChatStore.isTopBarClickable) return;
		divRef.current?.classList.add('top-bar-icon--clicked');
		UserInfoStore.token && createUserSessionMutation.mutate();

		setTimeout(() => {
			divRef.current?.classList.remove('top-bar-icon--clicked');
		}, 500);
	};

	useLayoutEffect(() => {
		if (ChatStore.curChat.sessionId) {
			ChatStore.setTopBarTitle(ChatStore.curChat.title);
		}
	}, [ChatStore.curChat.sessionId]);

	const chatTopBarClassnames = cx('chat-top-bar', {
		'chat-top-bar--is-not-clickable': !ChatStore.isTopBarClickable
	});

	return (
		<div className={chatTopBarClassnames}>
			<div
				className="top-bar-icon"
				onClick={() => {
					clickMenu();
				}}
			>
				{/* <LordIcon src={TopBarStore.menuIconUrl} {...lordIconProps} /> */}
				<img src={MenuIcon} alt="" />
			</div>

			<div className="top-bar-title">{ChatStore?.topBarTitle ?? ''}</div>
			<div
				className="top-bar-icon"
				onClick={() => {
					clickAdd();
				}}
				ref={divRef}
			>
				{/* <LordIcon src={'https://cdn.lordicon.com/ynwbvguu.json'} {...lordIconProps} /> */}
				<img src={NewPlus2} alt="" />
			</div>
		</div>
	);
};

export default observer(ChatTopBar);
