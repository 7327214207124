import { makeAutoObservable } from 'mobx';

export class ServiceCenterStore {
	/* =============================== 状态 ================================ */
	topBarTitle = '服务中心';
	userVipConfig = {};
	userVipPackage = {};
	vipTypeList = [];
	currentService = {}; // vipTypeCode: MEMBER: 基本服务, SUPER_MEMBER: 高级服务
	pickedServicePackage = {};
	userVipInfoTrigger = false;

	constructor(rootStore) {
		makeAutoObservable(this, {
			rootStore: false
		});
		this.rootStore = rootStore;
	}

	/* =============================== 计算属性 ================================ */
	get isNonMember() {
		return this.userVipConfig?.vipTypeCode === 'NON_MEMBER';
	}

	get isSuperMember() {
		return this.userVipConfig?.vipTypeCode === 'SUPER_MEMBER';
	}

	get isPayButtonDisabled() {
		return this.pickedServicePackage?.rechargeType === 4;
	}

	get isInTheVipTypeList() {
		const res = this.vipTypeList.some(item => item.vipType === this.currentService?.vipType);
		return res;
	}

	get memberTime() {
		if (this.userVipConfig?.vipTypeCode === 'NON_MEMBER') {
			return `今日剩余对话次数：${this.userVipConfig?.dailyRemainingChatTimes ?? 0} 次`;
		}

		if (this.userVipConfig?.vipTypeCode === 'SUPER_MEMBER') {
			return `有效期至: ${this.userVipConfig?.superExpireAt?.split(' ')[0] ?? '--'}`;
		}

		return `有效期至: ${this.userVipConfig?.expireAt?.split(' ')[0] ?? '--'}`;
	}

	get memberTimeTextInServiceCenter() {
		if (this.userVipConfig?.vipTypeCode === 'NON_MEMBER') {
			return `今日剩余对话次数：${this.userVipConfig?.dailyRemainingChatTimes ?? 0} 次`;
		}

		if (this.userVipConfig?.vipTypeCode === 'SUPER_MEMBER') {
			return `
				高级服务有效期至:${this.userVipConfig?.superExpireAt?.split(' ')[0] ?? '--'}
				<br />
				基础服务有效期至:${this.userVipConfig?.expireAt?.split(' ')[0] ?? '--'}
			`;
		}

		return `有效期至: ${this.userVipConfig?.expireAt?.split(' ')[0] ?? '--'}`;
	}

	get rechargeTypeStatusText() {
		const cfg = {
			1: '开通',
			2: '续费',
			3: '升级',
			4: ''
		};

		return cfg[this.pickedServicePackage?.rechargeType] ?? '';
	}

	/* =============================== 动作 ================================ */
	toggleUserVipInfoTrigger() {
		this.userVipInfoTrigger = !this.userVipInfoTrigger;
	}

	setTopBar(batTxt = '') {
		this.topBarTitle = batTxt;
	}

	setCurrentService(service = {}) {
		this.currentService = service;
	}

	setUserVipConfig(data) {
		this.userVipConfig = data;
	}

	setUserVipPackage(data) {
		this.userVipPackage = data;
	}

	setVipTypeList(vipTypeList = []) {
		this.vipTypeList = vipTypeList;
	}

	setPickedServicePackage(servicePackage = {}) {
		this.pickedServicePackage = servicePackage;
	}
}
