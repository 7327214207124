import { makeAutoObservable } from 'mobx';

export class GlobalCurtainStore {
	isGlobalCurtainShow = false;

	constructor(rootStore) {
		makeAutoObservable(this, {
			rootStore: false
		});
		this.rootStore = rootStore;
	}

	/* =============================== 计算属性 ================================ */

	/* =============================== 方法 ================================ */
	setIsGlobalCurtainShow(bool) {
		this.isGlobalCurtainShow = bool;
	}
}
