import { isDev, isTest } from '../Env';

export const TEST_ENV_URL = 'https://testapi.robochat.club';
export const PROD_ENV_URL = 'https://api.robochat.club';

export const BASE_URL = isDev || isTest ? TEST_ENV_URL : PROD_ENV_URL;

// *[全局配置](https://quentinqi.feishu.cn/wiki/wikcnPACef86P9zf3OXQUQKl6Ad)
export const CONFIG_GLOBAL = `${BASE_URL}/api/v1/config/global`;

// *[用户授权接口](https://quentinqi.feishu.cn/wiki/wikcnCUNN6Slg7qpNVm0DpywVrb)
export const USER_AUTH = `${BASE_URL}/api/v1/user/auth`;

// *[查询用户信息](https://quentinqi.feishu.cn/wiki/wikcnQdtgdCUOhEkYIgOXFQHw8g)
export const USER_INFO = `${BASE_URL}/api/v1/user/info`;

// *[创建会话](https://quentinqi.feishu.cn/wiki/wikcnbbKlNnLDJ9GozfLkhZcCIg)
export const SESSION_CREATE = `${BASE_URL}/api/v1/session/create`;

// *[删除会话](https://quentinqi.feishu.cn/wiki/wikcneIgTgqtQoVZU9Odr4kOR6b)
export const SESSION_DELETE = `${BASE_URL}/api/v1/session/delete`;

// *[查看用户会话记录](https://quentinqi.feishu.cn/wiki/wikcnyXmbFv37bJzj720Kpyv0jf)
export const SESSION_LIST = `${BASE_URL}/api/v1/session/list`;

// *[聊天内容预发送](https://quentinqi.feishu.cn/wiki/T3Ypw8IxtiQEsxkAf7yc2Mw4nFh)
export const CHAT_CONTENT_PRE_POST = `${BASE_URL}/api/v1/chat/content/prepost`;

// *[发送聊天内容](https://quentinqi.feishu.cn/wiki/wikcnUbb1gzAdiUgWTrsRHBjvZg)
export const CHAT_CONTENT_POST = `${BASE_URL}/api/v1/chat/content/post`;

// *[拉取聊天内容](https://quentinqi.feishu.cn/wiki/wikcnSCistxyXfGb0Fd6x8sbIkb)
export const CHAT_CONTENT_PAGE = `${BASE_URL}/api/v1/chat/content/page`;

// *[预设角色配置接口](https://quentinqi.feishu.cn/wiki/wikcnosfiHS2tzV2yMUmYSzPoEv)
export const CONFIG_CAREERS = `${BASE_URL}/api/v1/config/careers`;

// *[获取用户配置](https://quentinqi.feishu.cn/wiki/YqvIwaCKNiRhiakVKCHcl9H2nfd)
export const GET_USER_CONFIG = `${BASE_URL}/api/v1/user/getUserConfig`;

// *[设置用户配置开关状态](https://quentinqi.feishu.cn/wiki/QWmZw9sjniQYbQkwbL7c2W8Cnnc)
export const SAVE_USER_CONFIG_STATUS = `${BASE_URL}/api/v1/user/saveUserConfigStatus`;

// *[获取微信 jsapi_ticket](https://quentinqi.feishu.cn/wiki/SHC2wAXwuiC9fKkpg9cc8sFMnzh)
export const WE_CHAT_API_TICKET = `${BASE_URL}/api/v1/wechatApi/getJsApiTicket`;

// *[获取 VIP 类型](https://quentinqi.feishu.cn/wiki/JK3hwcb6AiYjeDkeqL1cLBeVnJc)
export const VIP_TYPE_LIST = `${BASE_URL}/api/v1/vipType/getVipTypeList`;

// *[获取会员套餐](https://quentinqi.feishu.cn/wiki/VDVqwvBjJimmumkig3McWH26nih)
export const VIP_PACKAGE_BY_TYPE = `${BASE_URL}/api/v1/vipType/getVipPackageByType`;

// *[获取用户会员信息](https://quentinqi.feishu.cn/wiki/XqOEwNv1vitk7hkX4BUcdmq8nUc)
export const USER_VIP_INFO = `${BASE_URL}/api/v1/userVip/getUserVipInfo`;

// *[获取邀请码](https://quentinqi.feishu.cn/wiki/ChVzwDwX4iRGV4kyptWc4C2onjb)
export const INVITE_CODE_CREATE = `${BASE_URL}/api/v1/inviteCode/create`;

// *[绑定邀请码](https://quentinqi.feishu.cn/wiki/DKPswf0LqiwVk7kEsbncPFdCnGW)
export const INVITE_CODE_BIND = `${BASE_URL}/api/v1/inviteCode/bind`;

// *[邀请人列表](https://quentinqi.feishu.cn/wiki/BJYPwhj4PimcIikG7C5ceTnnnod)
export const INVITEE_LIST = `${BASE_URL}/api/v1/inviteCode/inviteeList`;

// *[创建订单](https://quentinqi.feishu.cn/wiki/JXI8wJQWpiGSPkkT14OcSQE4nNh)
export const CREATE_ORDER = `${BASE_URL}/api/v1/order/createOrder`;

// *[获取微信配置]()
export const WE_CHAT_CONFIG = `${BASE_URL}/api/v1/wechatApi/getWechatConfig`;

// *[查询订单状态](https://quentinqi.feishu.cn/wiki/CQ5qw5N8Gi52Cyko4KVcuzixnbX)
export const ORDER_STATUS = `${BASE_URL}/api/v1/order/orderStatus`;
