/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { useRootStore } from '@/stores';
import './ServiceCenter.scss';

// 子组件
import ServiceDesc from './widgets/ServiceDesc/ServiceDesc';
import ServiceIntro from './widgets/ServiceIntro/ServiceIntro';
import BenefitsList from './widgets/BenefitsList/BenefitsList';

// 图片
import backIcon from '@/imgs/icon/back.svg';

// api
import { useGetUserVipInfoApi, useGetVipTypeListApi, useGetVipPackageByTypeApi, useCreateOrderApi } from '@/api';

const ServiceCenter = () => {
	const { ServiceCenterStore, UserInfoStore } = useRootStore();
	const getVipTypeListMutation = useGetVipTypeListApi();
	const getUserVipInfoMutation = useGetUserVipInfoApi();
	const getVipPackageByTypeMutation = useGetVipPackageByTypeApi();
	const createOrderMutation = useCreateOrderApi();
	const navigate = useNavigate();

	useEffect(() => {
		!UserInfoStore.isLogin && navigate('/chat');
	}, []);

	useEffect(() => {
		UserInfoStore.token && getUserVipInfoMutation.mutate();
	}, [UserInfoStore.token, ServiceCenterStore.userVipInfoTrigger]);

	useEffect(() => {
		UserInfoStore.token && getVipTypeListMutation.mutate();
	}, [UserInfoStore.token]);

	useEffect(() => {
		if (ServiceCenterStore.vipTypeList.length === 0) return;
		const params = {
			vipTypeId: ServiceCenterStore.isInTheVipTypeList ? ServiceCenterStore.currentService.vipType : 1
		};
		UserInfoStore.token && getVipPackageByTypeMutation.mutate(params);
	}, [UserInfoStore.token, ServiceCenterStore.currentService?.vipType, ServiceCenterStore.vipTypeList]);

	const backToPrev = () => {
		navigate(-1);
	};

	const confirmToPay = async () => {
		const payParams = {
			packageId: ServiceCenterStore.pickedServicePackage.id,
			vipTypeId: ServiceCenterStore.pickedServicePackage.vipTypeId
		};

		createOrderMutation.mutate(payParams);
	};

	return (
		<div className="service-center-page">
			<div className="service-center-top-bar">
				<img
					onClick={() => {
						backToPrev();
					}}
					className="service-center-left-icon"
					src={backIcon}
					alt=""
				/>
				<div className="service-center-title">{ServiceCenterStore.topBarTitle}</div>
			</div>

			<ServiceDesc />
			<ServiceIntro />
			<BenefitsList />

			<div className="service-center-button-area">
				{ServiceCenterStore.isPayButtonDisabled ? null : (
					<div
						className={cx('service-center-button-area-save-how', {
							show: !!ServiceCenterStore.pickedServicePackage.alreadySaved
						})}
					>
						<div className="service-center-save-text">
							已省：
							<span className="price-number">
								&yen;{`${ServiceCenterStore.pickedServicePackage.alreadySaved}`}
							</span>
						</div>
					</div>
				)}

				<div
					className={cx('service-center-button', {
						disabled: ServiceCenterStore.isPayButtonDisabled
					})}
					onClick={() => {
						confirmToPay();
					}}
				>
					<div className={cx('service-center-button-text')}>
						{ServiceCenterStore.isPayButtonDisabled ? (
							'您已是高级用户'
						) : (
							<>
								{' '}
								<span>确认协议支付&yen;</span>
								<span className="price-number">
									{ServiceCenterStore.pickedServicePackage.packagePrice}
								</span>
								{ServiceCenterStore.rechargeTypeStatusText}
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default observer(ServiceCenter);
