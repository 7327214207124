import React from 'react';
import { observer } from 'mobx-react';
import './LoadingDots.scss';

const LoadingDots = () => {
	return (
		<div className="loading-dots">
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
};

export default observer(LoadingDots);
