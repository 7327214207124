/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { CREATE_ORDER } from '@/config';
import { handleResponse } from '@/utils';

export const useCreateOrderApi = () => {
	const { UserInfoStore, WeChatStore } = useRootStore();

	const createOrder = ({ packageId, vipTypeId }) => {
		const params = {
			packageId,
			vipTypeId
		};
		return axios.post(CREATE_ORDER, params, {
			headers: {
				token: UserInfoStore.token
			}
		});
	};

	const createOrderMutation = useMutation(createOrder, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				console.log('orderNeedParams', data);
				WeChatStore.toPay(data);
			}
		},
		onError: err => {
			console.error('useCreateOrderApi', err);
		},
		onSettled: () => {}
	});

	return createOrderMutation;
};
