/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { CONFIG_CAREERS } from '@/config';
import { handleResponse } from '@/utils';

export const useConfigCareersApi = () => {
	const { ChatStore, UserInfoStore } = useRootStore();

	const config = {
		params: {},
		headers: {
			token: UserInfoStore.token
		}
	};

	const configCareer = () => {
		return axios.get(CONFIG_CAREERS, config);
	};

	const configCareersMutation = useMutation(configCareer, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				const { list = [] } = data;
				ChatStore.setCareerList(list);
			}
		},
		onError: err => {
			console.error('useConfigCareersApi', err);
		}
	});

	return configCareersMutation;
};
