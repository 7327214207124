/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { useNavigate } from 'react-router-dom';
import { handleResponse } from '@/utils';
import { SESSION_DELETE } from '@/config';

// 【hook】创建会话
export const useSessionDeleteApi = () => {
	const { ChatStore, UserInfoStore } = useRootStore();
	const navigate = useNavigate();

	const sessionDelete = params => {
		return axios.post(SESSION_DELETE, params, {
			headers: {
				token: UserInfoStore.token
			}
		});
	};

	const sessionDeleteMutation = useMutation(sessionDelete, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				const { list } = data;
				const curChat = list[0];

				ChatStore.setCurChat(curChat);
				ChatStore.setChatList(list);
				ChatStore.setMessages([]);
				const navUrl = `/chat/${curChat.sessionId}`;
				navigate(navUrl);

				return;
			}
		},
		onError: err => {
			console.error('useSessionDeleteApi', err);
		}
	});

	return sessionDeleteMutation;
};
