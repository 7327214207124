/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import LocalForageHelper from '@/utils/storageHelper';
import { INVITE_CODE_CREATE, isDev, TEST_URL, PROD_URL } from '@/config';
import { handleResponse } from '@/utils';

// 图片
// import shareLogo from '@/imgs/pics/sharelogo.png';

// 【hook】获取用户邀请码
export const useCreateInviteCodeApi = () => {
	const { UserInfoStore } = useRootStore();

	const createInviteCode = params => {
		const config = {
			params,
			headers: {
				token: UserInfoStore.token
			}
		};

		return axios.get(INVITE_CODE_CREATE, config);
	};

	const createInviteCodeMutation = useMutation(createInviteCode, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				UserInfoStore.setInviteCode(data);
				// LocalForageHelper.set('inviteCode', data.inviteCode);

				const inviteCode = data.inviteCode;
				const imgUrl = 'https://image-1315225748.cos.ap-beijing.myqcloud.com/sharelogo120.png';
				const link = `${isDev ? TEST_URL : PROD_URL}?inviteCode=${inviteCode}`;
				const title = '灵悉AI助手';
				const desc = '创造灵感，洞悉未知。解答你对过往未来的所有问题！';

				window.wx &&
					window.wx.ready(() => {
						window.wx.updateAppMessageShareData({
							title, // 分享标题
							desc, // 分享描述
							link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
							imgUrl, // 分享图标
							success: res => {
								console.log('设置分享成功', res);
							},
							fail: err => {
								console.log('设置分享失败', err);
							}
						});

						window.wx.updateTimelineShareData({
							title, // 分享标题
							link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
							imgUrl, // 分享图标
							success: res => {
								console.log('设置PYQ分享成功', res);
							},
							fail: err => {
								console.log('设置PYQ分享失败', err);
							}
						});
					});

				return;
			}
		},
		onError: err => {},
		onSettled: (data, error, variables, context) => {}
	});

	return createInviteCodeMutation;
};
