/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useRootStore } from '@/stores';
import './MembershipCard.scss';

// 图片
import SilverBadge from '@/imgs/pics/badge_silver.png';
import GoldBadge from '@/imgs/pics/badge_gold.png';

export const MembershipCard = () => {
	const { UserInfoStore, ServiceCenterStore, WeChatStore } = useRootStore();
	const [isAvatarError, setIsAvatarError] = useState(false);
	const navigate = useNavigate();

	const avatarError = () => {
		setIsAvatarError(true);
	};

	const membershipCardClassnames = cx('mine-membership-card', {
		silver: UserInfoStore.userInfoData?.vipInfo?.vipType === 'MEMBER',
		gold: UserInfoStore.userInfoData?.vipInfo?.vipType === 'SUPER_MEMBER'
	});

	const prolongBtnClassnames = cx('prolong-btn', {
		silver: UserInfoStore.userInfoData?.vipInfo?.vipType === 'MEMBER',
		gold: UserInfoStore.userInfoData?.vipInfo?.vipType === 'SUPER_MEMBER'
	});

	const badgeSrc = UserInfoStore.userInfoData?.vipInfo?.vipType === 'MEMBER' ? SilverBadge : GoldBadge;

	const toProLong = () => {
		if (!UserInfoStore.isLogin) {
			WeChatStore.wxLogin();
			return;
		}

		const serviceCenterRoute = '/service-center';
		navigate(serviceCenterRoute);
	};

	return (
		<div className="mine-membership-card-container">
			<div className={membershipCardClassnames}>
				<div className="top-part">
					<div className="user-avatar-block">
						{isAvatarError ? (
							<div
								className="iconfont icon-user_avatar default-avatar"
								style={{ fontSize: '0.52rem', color: '#FFFFFF' }}
							></div>
						) : (
							<img
								src={UserInfoStore.userAvatar}
								alt="user-avatar"
								className="user-img"
								onError={() => {
									avatarError();
								}}
							/>
						)}
					</div>
					<div className="user-name-member-time">
						<div className="user-name">{UserInfoStore.userName}</div>
						<div
							className="member-time"
							dangerouslySetInnerHTML={{ __html: ServiceCenterStore.memberTime }}
						></div>
					</div>
				</div>

				<div className="bottom-part">
					<div className="service-badge-and-title">
						{ServiceCenterStore.isNonMember ? null : (
							<img className="service-badge" src={badgeSrc} alt="" />
						)}
						<div className="service-title">{UserInfoStore.cardServiceTitle}</div>
					</div>

					<div
						className={prolongBtnClassnames}
						onClick={() => {
							toProLong();
						}}
					>
						{UserInfoStore.cardBtnText}
					</div>
				</div>
			</div>
		</div>
	);
};

MembershipCard.displayName = 'MembershipCard';

export default observer(MembershipCard);
