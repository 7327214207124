/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useRootStore } from '@/stores';
import '@/styles/redirectToChat.css';

const RedirectToChat = () => {
	const { URLParamsStore } = useRootStore();
	const navigate = useNavigate();

	useEffect(() => {
		window.localStorage.setItem('scanUrl', window.location.href.split('#')[0]);
		const inviteCode = URLParamsStore.getInviteCode();

		navigate(`/chat?inviteCode=${inviteCode}`);
	}, []);

	return (
		<div className="redirect-to-chat-wrapper">
			<h1 className="redirect-to-chat-slogan">
				<span>{'欢迎来到灵悉 AI 助手'}</span>
				{/* <span>{window.location.href}</span> */}
			</h1>
		</div>
	);
};

export default observer(RedirectToChat);
