/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { useRootStore } from '@/stores';
import { isDev, isTest } from '@/config';
import { formatDateTime } from '@/utils';
import './ChatSideLayer.scss';

// 子组件
import LoadingDots from '@/components/LoadingDots/LoadingDots';

// 图片
import newPlus from '@/imgs/icon/new_plus.svg';
import trash2 from '@/imgs/icon/trash-2.svg';
import to from '@/imgs/icon/to.svg';

// API
import {
	useSessionListApi,
	useSessionDeleteApi,
	useSessionCreateApi,
	useUserInfoApi,
	usePullChatContentApi
} from '@/api';

const ChatSideLayer = () => {
	const { ChatStore, UserInfoStore, WeChatStore, ServiceCenterStore, GlobalCurtainStore } = useRootStore();
	const [isAvatarError, setIsAvatarError] = useState(false);
	const [showDeleteConfirmForSession, setShowDeleteConfirmForSession] = useState(null);
	const [isSlidingDown, setIsSlidingDown] = useState(false);
	const nodeRef = useRef(null);
	const avatarRef = useRef(null);
	const navigate = useNavigate();
	const getUserSessionListMutation = useSessionListApi();
	const deleteUserSessionMutation = useSessionDeleteApi();
	const createUserSessionMutation = useSessionCreateApi();
	const getUserInfoMutation = useUserInfoApi();
	const pullChatContentMutation = usePullChatContentApi();

	const { isLoading: isUserSessionListLoading } = getUserSessionListMutation;
	const { isLoading: isUserInfoLoading } = getUserInfoMutation;
	const { isLoading: isDeleteUserSessionLoading } = deleteUserSessionMutation;
	const { isLoading: isPullingChatContent } = pullChatContentMutation;

	const clickCreateChatBtn = () => {
		ChatStore.setMessages([]);
		UserInfoStore.token && createUserSessionMutation.mutate();
	};

	const switchTheListItem = chat => {
		if (isPullingChatContent) return;

		if (chat.sessionId === ChatStore.curChat?.sessionId) {
			return;
		}

		ChatStore.setPullContent({});
		ChatStore.setMessages([]);
		ChatStore.setIsFirstTime(true);
		ChatStore.setTopBarTitle(chat?.title ?? '');
		ChatStore.setCurChat(chat);

		const chatRoute = `/chat/${chat?.sessionId ?? ''}`;
		navigate(chatRoute);
	};

	const clickTheDelete = (event, chat) => {
		event.stopPropagation();
		if (isDeleteUserSessionLoading) return;

		setShowDeleteConfirmForSession(chat?.sessionId ?? '');
	};

	const cancelDeleteConfirm = (event, chat) => {
		event.stopPropagation();
		setIsSlidingDown(true);

		setTimeout(() => {
			setIsSlidingDown(false);
			setShowDeleteConfirmForSession(null);
		}, 300);
	};

	const confirmDelete = (event, chat) => {
		event.stopPropagation();
		if (isDeleteUserSessionLoading) return;

		const deleteParams = {
			sessionId: chat?.sessionId ?? ''
		};
		UserInfoStore.token && deleteUserSessionMutation.mutate(deleteParams);
		setShowDeleteConfirmForSession(null);
	};

	const toLogin = () => {
		if (isUserInfoLoading) return;

		WeChatStore.wxLogin();
	};

	const toUpdate = () => {
		if (isUserInfoLoading) return;

		GlobalCurtainStore.setIsGlobalCurtainShow(false);
		ChatStore.setIsSideLayerShow(false);

		const serviceCenterRoute = '/service-center';
		navigate(serviceCenterRoute, { replace: true });
	};

	const avatarError = () => {
		setIsAvatarError(true);
	};

	const handleScroll = event => {
		event.preventDefault();
	};

	useEffect(() => {
		return () => {
			ChatStore.setIsFirstTime(true);
		};
	}, []);

	useEffect(() => {
		if (ChatStore.isSideLayerShow === true && UserInfoStore.token) {
			getUserSessionListMutation.mutate();
		}
	}, [UserInfoStore.token, ChatStore.isSideLayerShow]);

	useEffect(() => {
		const getUserInfoParam = {
			openId: isTest
				? WeChatStore.authDataFromWeChat?.openId
				: isDev
				? '123'
				: WeChatStore.authDataFromWeChat?.openId
		};
		getUserInfoParam.openId && getUserInfoMutation.mutate(getUserInfoParam);
	}, [WeChatStore.authDataFromWeChat?.openId]);

	useEffect(() => {
		const layer = document.querySelector('#chat-list-unique-id');
		layer && layer.addEventListener('scroll', handleScroll);
		return () => {
			layer && layer.removeEventListener('scroll', handleScroll);
		};
	}, [isUserSessionListLoading]);

	useEffect(() => {
		setIsAvatarError(false);
	}, [UserInfoStore.isLogin]);

	const clickThePrivilegeBtn = () => {
		toUpdate();
	};

	const nodeClassnames = cx('chat-side-layer', {
		active: ChatStore.isSideLayerShow
	});

	const createNewChatClassnames = cx('create-new-chat', {
		disabled: isUserSessionListLoading || isDeleteUserSessionLoading
	});

	const loginAndUpdateClassnames = cx('login-and-update-button', {
		isNotLogin: !UserInfoStore.isLogin
	});

	const chatListClassnames = cx('chat-list', {
		isLogin: UserInfoStore.isLogin
	});
	// WeChatStore.wxLogin();
	const chatListServiceClassnames = cx('chat-list-user-service', {
		isLoadingData: isUserInfoLoading
	});

	const privilegesClassnames = cx('privilege-button', {
		free: UserInfoStore.userInfoData?.vipInfo?.vipType === 'NON_MEMBER',
		basic: UserInfoStore.userInfoData?.vipInfo?.vipType === 'MEMBER',
		advance: UserInfoStore.userInfoData?.vipInfo?.vipType === 'SUPER_MEMBER'
	});

	// console.log('userDataInfo', UserInfoStore.userInfoData.avatarUrl);

	return (
		<div className={nodeClassnames} ref={nodeRef}>
			<div className="chat-side-layer-title">{ChatStore.chatSideLayerTitle}</div>
			{/* 添加新对话按钮 */}
			<div
				className={createNewChatClassnames}
				onClick={() => {
					clickCreateChatBtn();
				}}
			>
				<img src={newPlus} alt="" />
				<div className="create-new-chat-btn-text">{ChatStore.createNewChatBtnTxt}</div>
			</div>
			{isUserSessionListLoading ? (
				<div className={'chat-list-loading-area'}>
					<LoadingDots />
				</div>
			) : (
				<ul className={chatListClassnames} id="chat-list-unique-id">
					{ChatStore.chatList.map(chat => {
						const isActive = ChatStore.curChat?.sessionId === chat.sessionId;
						const iconColor = isActive ? '#92959E' : '#92959E';

						const messagesAndDeletePartClassnames = cx('messages-and-delete-part', {
							'slide-up': showDeleteConfirmForSession === chat.sessionId
						});

						const deleteConfirmPartClassnames = cx('delete-confirm-part', {
							'slide-up': showDeleteConfirmForSession === chat.sessionId,
							'slide-down': isSlidingDown
						});

						const confirmDeleteTextClassnames = cx('confirm-delete-text', {
							'slide-in': showDeleteConfirmForSession === chat.sessionId,
							'slide-out': isSlidingDown
						});

						const topicPartClassnames = cx('topic-part', {
							hidden: showDeleteConfirmForSession === chat.sessionId
						});

						const datePartClassnames = cx('date-part', {
							hidden: showDeleteConfirmForSession === chat.sessionId
						});

						return (
							<li
								className={cx('chat-list-item', {
									active: isActive
								})}
								key={chat.sessionId}
								onClick={() => {
									switchTheListItem(chat);
								}}
							>
								<div className="topic-and-date-part">
									<div className={topicPartClassnames}>{chat?.title ?? '新会话'}</div>
									<div className={datePartClassnames}>{formatDateTime(chat.ctime ?? '')}</div>
									<div className={confirmDeleteTextClassnames}>确认删除？</div>
								</div>

								{showDeleteConfirmForSession !== chat.sessionId ? (
									<div className={messagesAndDeletePartClassnames}>
										<div className="messages-part">{chat?.intro ?? ''}</div>
										<div
											className="delete-part"
											onClick={event => {
												clickTheDelete(event, chat);
											}}
										>
											<img src={trash2} alt="" />
										</div>
									</div>
								) : (
									<div className={deleteConfirmPartClassnames}>
										<div
											className="delete-confirm-cancel"
											onClick={event => {
												cancelDeleteConfirm(event, chat);
											}}
										>
											{'取消'}
										</div>

										<div
											className="delete-confirm-confirm"
											onClick={event => {
												confirmDelete(event, chat);
											}}
										>
											{'确认'}
										</div>
									</div>
								)}
							</li>
						);
					})}
				</ul>
			)}

			{/* 对话列表 */}

			{/* 前往服务中心部分 */}
			<div className={chatListServiceClassnames}>
				{/* 用户信息 */}
				<div
					className="user-brief-info"
					onClick={() => {
						UserInfoStore.isLogin ? clickThePrivilegeBtn() : toLogin();
					}}
				>
					<div className="user-avatar">
						{isAvatarError ? (
							<div className="iconfont icon-user_avatar default-avatar"></div>
						) : (
							<img
								ref={avatarRef}
								src={UserInfoStore.userAvatar}
								alt=""
								className="user-img"
								onError={() => {
									avatarError();
								}}
							/>
						)}
					</div>
					<div className="user-info-block">
						<div className="user-info-name">{UserInfoStore.userName}</div>
						<div className="privilege-block">
							{UserInfoStore.isLogin ? (
								<div className={privilegesClassnames}>{UserInfoStore.privilegeName}</div>
							) : null}
						</div>
					</div>
					<div className="to-service-center">
						<img src={to} alt="" />
					</div>
				</div>
				{/* 服务剩余时间 */}
				{UserInfoStore.isLogin ? (
					<div className="service-rest-time">
						{UserInfoStore.userInfoData?.vipInfo?.vipType === 'NON_MEMBER' ? null : (
							<div className="service-rest-time-title">{ServiceCenterStore.memberTime}</div>
						)}
					</div>
				) : null}
				{/* 登录按钮 */}
				<div className={loginAndUpdateClassnames}>
					{UserInfoStore.isLogin ? (
						<div
							className="to-update-button"
							onClick={() => {
								toUpdate();
							}}
						>
							{UserInfoStore.userInfoData?.vipInfo?.vipType === 'NON_MEMBER'
								? '升级服务每日可对话50次'
								: '续费'}
						</div>
					) : (
						<div
							className="login-button"
							onClick={() => {
								toLogin();
							}}
						>
							<div className="login-button-main-text">登录</div>
							<div className="login-button-explain-text">获取每日 10 次免费对话</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

ChatSideLayer.displayName = 'ChatSideLayer';

export default observer(ChatSideLayer);
