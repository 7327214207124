/* eslint-disable no-unused-vars */
// import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { CHAT_CONTENT_POST } from '@/config';
// import { handleResponse } from '@/utils';

export const useSendChatContentApi = () => {
	const { ChatStore, UserInfoStore } = useRootStore();

	const sendChatContent = async ({ sessionId = '', role = '', content = '', encryptDigest = '' }) => {
		const params = { sessionId, role, content, encryptDigest, careerId: ChatStore.pickedCareer?.id ?? '' };
		const response = await fetch(CHAT_CONTENT_POST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				token: UserInfoStore.token,
				accept: '*/*',
				'accept-encoding': 'gzip, deflate, br',
				'accept-language': 'zh-CN,zh;q=0.9'
			},
			body: JSON.stringify(params)
		});

		if (!response.ok) {
			throw new Error(`Network error: ${response.status}`);
		}

		const reader = response.body.getReader();
		const decoder = new TextDecoder('utf-8');

		let buffer = '';

		const scrollToBottomInterval = setInterval(() => {
			ChatStore.toggleScrollToBottomTrigger();
		}, 1000);

		const processBuffer = async () => {
			const regex = /data:({[^]*?})\n\n/g;
			regex.lastIndex = 0;

			let adjustedLastIndex = 0;

			let match;
			while ((match = regex.exec(buffer)) !== null) {
				const charNew = JSON.parse(match[1]).content;

				const latestMessage = ChatStore.getTheLatestMessage();
				const { chatRecordId: latestMessageId, content: prevText } = latestMessage;

				await new Promise(resolve =>
					setTimeout(() => {
						const newText = prevText + charNew;
						// console.log('prevText newText', [prevText, newText]);
						ChatStore.updateMessage(latestMessageId, newText);

						resolve();
					}, 20)
				);

				adjustedLastIndex = regex.lastIndex;
			}

			buffer = buffer.slice(adjustedLastIndex);
		};

		return new Promise(async resolve => {
			while (true) {
				const { done, value } = await reader.read();

				if (value) {
					const partialResponse = decoder.decode(value, { stream: true });

					buffer += partialResponse;
					await processBuffer();
				}
				if (done) {
					clearInterval(scrollToBottomInterval);
					break;
				}
			}

			return resolve(buffer);
		}).catch(err => {
			console.error('useSendChatContentApi', err);
			clearInterval(scrollToBottomInterval);
		});
	};

	const sendChatContentMutation = useMutation(sendChatContent, {
		onMutate: variables => {
			ChatStore.setIsTopBarClickable(false);
			ChatStore.addFakeAssistantMessage();
		}, // 用于在发生突变之前，对缓存进行任何手动更新
		onSuccess: (resData, variables) => {
			// console.log('get resData', resData);
		},
		onError: err => {
			console.error('useSendChatContentApi', err);
		},
		onSettled: (resData, err, variables, context) => {
			ChatStore.toggleScrollToBottomTrigger();
			ChatStore.setIsTopBarClickable(true);
		}
	});

	return sendChatContentMutation;
};
