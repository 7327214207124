/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { useRootStore } from '@/stores';
import './ServiceIntro.scss';

// 图片
import SilverBadge from '@/imgs/pics/badge_silver.png';
import GoldBadge from '@/imgs/pics/badge_gold.png';

const ServiceIntro = props => {
	const { ServiceCenterStore } = useRootStore();

	return (
		<div className="service-intro-block">
			<div className="service-intro-tabs">
				{ServiceCenterStore.vipTypeList.length === 0
					? null
					: ServiceCenterStore.vipTypeList.map(service => {
							const isActive = ServiceCenterStore.currentService?.vipTypeCode === service.vipTypeCode;
							const alsoActive =
								ServiceCenterStore.currentService?.vipTypeCode === 'NON_MEMBER' &&
								service.vipTypeCode === 'MEMBER';

							return (
								<div
									key={service.vipType}
									className={cx('service-intro-tab', {
										active: isActive || alsoActive
									})}
									onClick={() => {
										ServiceCenterStore.setCurrentService(service);
									}}
								>
									<img
										className="service-badge"
										src={service.vipTypeCode === 'MEMBER' ? SilverBadge : GoldBadge}
										alt=""
									/>
									<div className="service-intro-tab-text">
										{service.vipName}
										{/* <span className="small-font">{service.limitation}</span> */}
									</div>
								</div>
							);
					  })}
			</div>

			<div className="service-intro-content">
				<div className="service-list">
					{ServiceCenterStore.userVipPackage?.vipPackageList?.map(service => {
						const isPicked = ServiceCenterStore.pickedServicePackage?.id === service.id;

						return (
							<div
								className={cx('service-item', { isPicked })}
								key={service.id}
								onClick={() => {
									ServiceCenterStore.setPickedServicePackage(service);
								}}
							>
								<div className="service-item-name">{service.packageTitle}</div>
								<div className="service-item-duration">{service.packageName}</div>
								<div className="service-item-price">
									<span>&yen;{`${service.packagePrice}`}</span>
								</div>
								{service.alreadySaved ? (
									<div className="service-item-already-saved">&yen;{service.originalPrice}</div>
								) : null}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default observer(ServiceIntro);
