import { makeAutoObservable } from 'mobx';

export class NavBarStore {
	navMenu = [];

	constructor(rootStore) {
		makeAutoObservable(this, {
			rootStore: false
		});
		this.rootStore = rootStore;
	}

	/* =============================== 计算属性 ================================ */

	/* =============================== 方法 ================================ */
	setNavMenu(newNavMenu) {
		this.navMenu = newNavMenu;
	}
}
