/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { useRootStore } from '@/stores';
import './ChatInputBar.scss';

// import { Markdown } from '@/components/MarkDown/MarkDown';

// 图片
import buttonSend from '@/imgs/icon/button_send.svg';

// API
import { useSendChatContentApi, useChatContentPrepostApi } from '@/api';

const ChatInputBar = () => {
	const { ChatStore, UserInfoStore, WeChatStore } = useRootStore();
	const [isSendActive, setIsSendActive] = useState(false);
	const presendChatContentMutation = useChatContentPrepostApi();
	const sendChatContentMutation = useSendChatContentApi();
	const { isLoading: isPreSendLoading } = presendChatContentMutation;
	const { isLoading: isSendingContent } = sendChatContentMutation;

	useEffect(() => {}, []);

	const clickTheSendBtn = async () => {
		// console.log('ChatStore.curChat', ChatStore.curChat);

		if (!UserInfoStore.isLogin) {
			WeChatStore.wxLogin();
			return;
		}

		if (!ChatStore.curChat.sessionId) {
			return;
		}

		if (!ChatStore.inputValue.trim()) {
			return;
		}

		// 这里触发点击发送特效
		setIsSendActive(true);
		setTimeout(() => {
			setIsSendActive(false);
		}, 1000);

		if (UserInfoStore.token) {
			const content = ChatStore.inputValue;

			const preSendParams = {
				content,
				role: 'user',
				sessionId: ChatStore.curChat.sessionId
			};

			await presendChatContentMutation.mutateAsync(preSendParams);

			const sendParams = {
				content,
				role: 'user',
				sessionId: ChatStore.curChat.sessionId,
				encryptDigest: ChatStore.encryptDigest
			};

			ChatStore.encryptDigest && (await sendChatContentMutation.mutateAsync(sendParams));
		}

		ChatStore.setEncryptDigest('');
	};

	const inputValueChange = e => {
		const newVal = e.target.value;
		ChatStore.setInputValue(newVal);
	};

	const sendBtnClassnames = cx('input-send-btn', {
		disabled: ChatStore.isNoInputValue || isPreSendLoading || isSendingContent
	});

	return (
		<div className={cx('chat-input-bar-container', { bottomZero: ChatStore.isInputFocus })}>
			<textarea
				className="chat-input"
				type="text"
				rows={ChatStore.isInputFocus ? 3 : 1}
				placeholder={ChatStore.inputPlaceholder}
				value={ChatStore.inputValue}
				onBlur={() => {
					ChatStore.setInputFocus(false);
				}}
				onFocus={() => {
					ChatStore.setInputFocus(true);
				}}
				onChange={e => {
					inputValueChange(e);
				}}
				onKeyDown={e => {
					if (e.key === 'Enter' && e.ctrlKey) {
						clickTheSendBtn();
					}
				}}
				inputMode="text"
				pattern="\S+"
			/>
			<div
				className={sendBtnClassnames}
				onTouchStart={() => {
					clickTheSendBtn();
				}}
			>
				<img
					className={cx('send-button-img', {
						'send-button-img-animate': isSendActive
					})}
					src={buttonSend}
					alt=""
				/>
			</div>
		</div>
	);
};

ChatInputBar.displayName = 'ChatInputBar';

export default observer(ChatInputBar);
