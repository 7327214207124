/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useRootStore } from '@/stores';
import { observer } from 'mobx-react';
import './Chat.scss';

// 子组件
import ChatTopBar from './widgets/ChatTopBar/ChatTopBar';
import ChatCareerPicker from './widgets/ChatCareerPicker/ChatCareerPicker';
import ChatInputBar from './widgets/ChatInputBar/ChatInputBar';

const Chat = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { RouterStore } = useRootStore();

	useEffect(() => {
		RouterStore.setStoreNavigate(navigate);
		RouterStore.setStoreLocation(location);
	}, [navigate, location]);

	return (
		<section className="chat-page">
			<ChatTopBar />
			<ChatCareerPicker />
			<Outlet />
			<ChatInputBar />
		</section>
	);
};

Chat.displayName = 'Chat';

export default observer(Chat);
