import { message } from 'antd';

export const copyToClipboard = async (text, isPickedCareer = false) => {
	try {
		await navigator.clipboard.writeText(text);
		message.success({
			key: 'copy-success',
			content: '已复制',
			duration: 1.5,
			style: {
				marginTop: isPickedCareer ? '1.3rem' : '2.8rem'
			}
		});
	} catch (error) {
		const textArea = document.createElement('textarea');
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			document.execCommand('copy');
			message.success({
				key: 'copy-success',
				content: '已复制',
				duration: 1.5,
				style: {
					marginTop: isPickedCareer ? '1.3rem' : '2.8rem'
				}
			});
		} catch (error) {
			message.error({
				key: 'copy-error',
				content: '已复制',
				duration: 1.5,
				style: {
					marginTop: isPickedCareer ? '1.3rem' : '2.8rem'
				}
			});
		}
		document.body.removeChild(textArea);
	}
};
