import React from 'react';
import { observer } from 'mobx-react';
// import cx from 'classnames';
import { useRootStore } from '@/stores';
import './BenefitsList.scss';

// 图片
import SilverBadge from '@/imgs/pics/badge_silver.png';
import GoldBadge from '@/imgs/pics/badge_gold.png';

const BenefitsList = props => {
	const { ServiceCenterStore } = useRootStore();

	const vipTypeCode = ServiceCenterStore.currentService?.vipTypeCode;
	const badgeSrc = vipTypeCode === 'MEMBER' ? SilverBadge : GoldBadge;

	return (
		<div className="benefits-list">
			<div className="benefits-list-title"></div>
			<ul className="benefits-block-list">
				{ServiceCenterStore.userVipPackage?.detail?.map((item, index) => {
					return (
						<li key={index} className="benefits-block-item">
							<img className="service-badge" src={badgeSrc} alt="" />
							<div className="detail-item-name">{item}</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default observer(BenefitsList);
