// 处理时间日期，后端返回的内容格式是 YYYY-MM-DD HH:MM:SS
// 我们需要的格式是，如果是今天的话，只显示 HH:MM，如果是昨天的话，显示昨天，如果是昨天之前的话，显示 YYYY-MM-DD
const formatDateTime = dateTime => {
	if (!dateTime) return;

	const inputDate = new Date(dateTime);
	const currentDate = new Date();
	const yesterday = new Date(currentDate);
	yesterday.setDate(yesterday.getDate() - 1);

	const isToday = inputDate.toDateString() === currentDate.toDateString();
	const isYesterday = inputDate.toDateString() === yesterday.toDateString();

	if (isToday) {
		return `${inputDate.getHours().toString().padStart(2, '0')}:${inputDate
			.getMinutes()
			.toString()
			.padStart(2, '0')}`;
	} else if (isYesterday) {
		return '昨天';
	} else {
		return `${inputDate.getFullYear()}-${(inputDate.getMonth() + 1).toString().padStart(2, '0')}-${inputDate
			.getDate()
			.toString()
			.padStart(2, '0')}`;
	}
};

export { formatDateTime };
