/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRootStore } from '@/stores';
import { observer } from 'mobx-react';
import { isDev, isTest, TEST_URL, PROD_URL } from '@/config';
import './Home.scss';

// 子组件
import GlobalCurtain from '@/components/GlobalCurtain/GlobalCurtain';
import NavBar from '@/components/NavBar/NavBar';
import ChatSideLayer from './widgets/ChatSideLayer/ChatSideLayer';

// api
import {
	useConfigGlobalApi,
	useSessionListApi,
	useGetUserConfigApi,
	useCreateInviteCodeApi,
	useBindInviteCodeApi,
	useUserAuthApi,
	useGetUserVipInfoApi
} from '@/api';

const Home = () => {
	const { ChatStore, GlobalCurtainStore, WeChatStore, UserInfoStore, URLParamsStore } = useRootStore();
	const getUserSessionListMutation = useSessionListApi();
	const getUserConfigMutation = useGetUserConfigApi();
	const createInviteCodeMutation = useCreateInviteCodeApi();
	const globalConfigMutation = useConfigGlobalApi();
	const bindInviteCodeMutation = useBindInviteCodeApi();
	const getUserAuthMutation = useUserAuthApi();
	const getUserVipInfoMutation = useGetUserVipInfoApi();

	useEffect(() => {
		async function setUrlOnload() {
			globalConfigMutation.mutate();
		}
		setUrlOnload();
	}, []);

	useEffect(() => {
		if (UserInfoStore.isLogin) {
			return;
		}
		// 进行微信用户授权
		const paramObj = {
			code: URLParamsStore.getCodeParamFromWXRedirect()
		};
		paramObj.code && getUserAuthMutation.mutate(paramObj);
	}, [URLParamsStore.getCodeParamFromWXRedirect()]);

	useEffect(() => {
		console.log('登录判断逻辑', [
			!ChatStore.globalConfig.wxAppId,
			isDev,
			isTest,
			URLParamsStore.getCodeParamFromWXRedirect()
		]);
		if (!ChatStore.globalConfig.wxAppId || isDev || isTest || URLParamsStore.getCodeParamFromWXRedirect()) {
			return;
		}

		WeChatStore.wxLogin();
	}, [ChatStore.globalConfig.wxAppId]);

	useEffect(() => {
		if (!UserInfoStore.isLogin) return;
		if (!UserInfoStore.inviteCode) return;
		if (UserInfoStore.inviteCodeBindResult === 'fail' && URLParamsStore.getInviteCode()) {
			const param = { inviteCode: URLParamsStore.getInviteCode() };
			UserInfoStore.token && bindInviteCodeMutation.mutate(param);
		}
	}, [UserInfoStore.isLogin, UserInfoStore.inviteCode, UserInfoStore.token]);

	useEffect(() => {
		if (UserInfoStore.inviteCode) return;
		UserInfoStore.token && createInviteCodeMutation.mutate();
	}, [UserInfoStore.token, UserInfoStore.inviteCode]);

	useEffect(() => {
		UserInfoStore.token && getUserConfigMutation.mutate();
	}, [UserInfoStore.token]);

	useEffect(() => {
		UserInfoStore.token && getUserVipInfoMutation.mutate();
	}, [UserInfoStore.token]);

	useEffect(() => {
		if (
			WeChatStore.WxConfig.signature &&
			UserInfoStore.isLogin &&
			ChatStore.globalConfig?.wxAppId &&
			UserInfoStore.inviteCode
		) {
			const inviteCode = UserInfoStore.inviteCode;
			const imgUrl = 'https://image-1315225748.cos.ap-beijing.myqcloud.com/sharelogo120.png';
			const link = `${isDev ? TEST_URL : PROD_URL}?inviteCode=${inviteCode}`;
			const title = '灵悉AI助手';
			const desc = '创造灵感，洞悉未知。解答你对过往未来的所有问题！';

			const options = {
				title, // 分享标题
				desc, // 分享描述
				link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl // 分享图标
			};

			WeChatStore.handleShareJsApi(options);
		}
	}, [
		WeChatStore.WxConfig.signature,
		UserInfoStore.isLogin,
		ChatStore.globalConfig?.wxAppId,
		UserInfoStore.inviteCode
	]);

	useEffect(() => {
		// 页面刚进入时请求一次会话列表
		if (UserInfoStore.token && window.location.pathname.includes('/chat')) {
			getUserSessionListMutation.mutate();
		}
	}, [UserInfoStore.token]);

	return (
		<section className="home-page">
			{GlobalCurtainStore.isGlobalCurtainShow && <GlobalCurtain />}
			<ChatSideLayer />

			<div className="children-container">
				<Outlet />
			</div>
			{ChatStore.isInputFocus ? null : <NavBar />}
		</section>
	);
};

Home.displayName = 'Home';

export default observer(Home);
