/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useRootStore } from '@/stores';
import { SESSION_LIST } from '@/config';
import { handleResponse } from '@/utils';

// 【hook】会话列表
export const useSessionListApi = () => {
	const { ChatStore, UserInfoStore } = useRootStore();
	const navigate = useNavigate();

	// console.log('token', UserInfoStore.token);

	const sessionList = () => {
		const config = {
			params: {},
			headers: {
				token: UserInfoStore.token
			}
		};
		return axios.get(SESSION_LIST, config);
	};

	const sessionListMutation = useMutation(sessionList, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				const { list } = data;
				const curChat = list.find(chat => chat.sessionId === ChatStore.curChat.sessionId);
				const latestChat = list[0];
				// console.log('curChat latestChat', [curChat, latestChat]);
				ChatStore.setChatList(list);
				ChatStore.setCurChat(curChat ? curChat : latestChat);

				const navUrl = `/chat/${curChat ? curChat.sessionId : latestChat.sessionId}`;
				navigate(navUrl);

				return;
			}
		},
		onError: err => {
			console.error('useSessionListApi', err);
		},
		onSettled: () => {
			ChatStore.toggleScrollToBottomTrigger();
		}
	});

	return sessionListMutation;
};
