import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { message as antdMessage } from 'antd';
import { CHAT_CONTENT_PRE_POST } from '@/config';
import { handleResponse } from '@/utils';

export const useChatContentPrepostApi = () => {
	const { UserInfoStore, ChatStore } = useRootStore();

	const isCareerPickerFoldOrHide =
		ChatStore.isCareerPickerFold || !ChatStore.isCareerPickerShow || ChatStore.isInputFocus;

	const preSendContent = ({ sessionId, role, content }) => {
		const params = {
			sessionId,
			role,
			content
		};
		return axios.post(CHAT_CONTENT_PRE_POST, params, {
			headers: {
				token: UserInfoStore.token
			}
		});
	};

	const preSendContentMutation = useMutation(preSendContent, {
		onMutate: () => {
			// 暂时不让切换会话，待信息返回之后再允许切换
			ChatStore.setIsTopBarClickable(false);
		},
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				const { prepostResult, failOptions } = data;

				if (prepostResult === 'success') {
					ChatStore.addFakeUserMessage();
					ChatStore.setEncryptDigest(data?.encryptDigest ?? '');
				} else {
					console.log('preSend failOptions', failOptions);

					const { type, message } = failOptions;

					if (type === 'chat_number_limitation') {
						ChatStore.setIsFreeTimesUsedUp(true);
					} else if (type === 'hit_sensitive_words') {
						antdMessage.error({
							key: 'hit_sensitive_words',
							content: message,
							duration: 1.5,
							style: {
								marginTop: isCareerPickerFoldOrHide ? '1.3rem' : '2.8rem'
							}
						});
					}
				}

				return;
			}
		},
		onError: err => {
			console.error('useChatContentPrepostApi', err);
			ChatStore.setEncryptDigest('');
		},
		onSettled: () => {
			ChatStore.setInputValue('');
			ChatStore.setIsTopBarClickable(true);
			ChatStore.toggleScrollToBottomTrigger();
		}
	});

	return preSendContentMutation;
};
