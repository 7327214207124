/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { redirect } from 'react-router-dom';
import { useRootStore } from '@/stores';
import './Mine.scss';

import MembershipCard from './widgets/MembershipCard/MembershipCard';
import InviteBlock from './widgets/InviteBlock/InviteBlock';
import SettingBlock from './widgets/SettingBlock/SettingBlock';
import InviteOverlay from './widgets/InviteOverlay/InviteOverlay';

// api
import { useCreateInviteCodeApi, useGetUserVipInfoApi } from '@/api';

const Mine = () => {
	const { UserInfoStore } = useRootStore();
	const createInviteCodeMutation = useCreateInviteCodeApi();
	const getUserVipInfoMutation = useGetUserVipInfoApi();

	useEffect(() => {
		if (UserInfoStore.inviteCode) return;
		UserInfoStore.token && createInviteCodeMutation.mutate();
	}, [UserInfoStore.token, UserInfoStore.inviteCode]);

	useEffect(() => {
		UserInfoStore.token && getUserVipInfoMutation.mutate();
	}, [UserInfoStore.token]);

	useEffect(() => {
		!UserInfoStore.isLogin && redirect('/chat');
	}, []);

	return (
		<section className="mine-page">
			<h1 className="mine-page-title">{'个人中心'}</h1>
			<MembershipCard />
			{UserInfoStore.isLogin ? <InviteBlock /> : null}
			<SettingBlock />
			<InviteOverlay />
		</section>
	);
};

Mine.displayName = 'Mine';

export default observer(Mine);
