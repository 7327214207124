import { UIState } from './UIState';
import { URLParamsStore } from './URLParamsStore';
import { RouterStore } from './RouterStore';
import { UserInfoStore } from './UserInfoStore';
import { GlobalCurtainStore } from './GlobalCurtainStore';
import { WeChatStore } from './WeChatStore';
import { NavBarStore } from './NavBarStore';
/* Pages */
import { ChatStore } from './ChatStore';
import { MineStore } from './MineStore';
import { ServiceCenterStore } from './ServiceCenterStore';

export class RootStore {
	constructor() {
		this.UIState = new UIState(this);
		this.RouterStore = new RouterStore(this);
		this.URLParamsStore = new URLParamsStore(this);
		this.UserInfoStore = new UserInfoStore(this);
		this.NavBarStore = new NavBarStore(this);
		this.GlobalCurtainStore = new GlobalCurtainStore(this);
		this.WeChatStore = new WeChatStore(this);
		this.ChatStore = new ChatStore(this);
		this.MineStore = new MineStore(this);
		this.ServiceCenterStore = new ServiceCenterStore(this);
	}
}
