import { makeAutoObservable } from 'mobx';

export class URLParamsStore {
	USP = new URLSearchParams(window.location.search);

	constructor(rootStore) {
		makeAutoObservable(this, {
			rootStore: false
		});
		this.rootStore = rootStore;
	}

	/* =============== 计算属性 =============== */

	/* =============== 方法 =============== */
	// 获取微信重定向回来的 code 参数
	getCodeParamFromWXRedirect() {
		return this.USP.get('code') ?? '';
	}

	// 获取邀请码
	getInviteCode() {
		return this.USP.get('inviteCode') ?? '';
	}

	// 获取登录后的状态
	getIsLoginParam() {
		return this.USP.get('isLogin') ?? '';
	}
}
