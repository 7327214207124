import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import VConsole from 'vconsole';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as MobxProvider } from 'mobx-react';
import { store } from '@/stores';
import router from '@/routes/main';
import LoadingPage from '@/components/LoadingPage/LoadingPage.jsx';
import { isDev, isTest } from '@/config';
import reportWebVitals from '@/reportWebVitals';
import '@/styles/index.css';
import '@/styles/iconfont.css';
import '@/styles/markdown.scss';
import '@/styles/highlight.scss';

const queryClient = new QueryClient({});

if (isDev || isTest) {
	new VConsole();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<QueryClientProvider client={queryClient}>
		<MobxProvider store={store}>
			<RouterProvider router={router} fallbackElement={<LoadingPage />} />
		</MobxProvider>
		{/* <ReactQueryDevtools position="top-right" /> */}
	</QueryClientProvider>
);

reportWebVitals();
