import {makeAutoObservable, observable} from 'mobx';


export class UIState {
  windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
};

  constructor(rootStore) {
    makeAutoObservable(this,  {
      windowDimensions: observable.struct,
    })

    this.rootStore = rootStore;

    window.onresize = () => {
      console.log('resize');
    };
  }

  /* =============== 计算属性 =============== */


  /* =============== 方法 =============== */
  getWindowDimensions() {
    this.windowDimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
    };
  }
}