import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { INVITEE_LIST } from '@/config';
import { handleResponse } from '@/utils';

export const useInviteeListApi = () => {
	const { UserInfoStore, MineStore } = useRootStore();

	const getInviteeList = () => {
		const config = {
			params: {},
			headers: {
				token: UserInfoStore.token
			}
		};

		return axios.get(INVITEE_LIST, config);
	};

	const getInviteeListMutation = useMutation(getInviteeList, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				// console.log('invitee list data', data);
				MineStore.setInviteeListData(data);
				return;
			}
		},
		onError: err => {
			console.error('useInviteeListApi', err);
		},
		onSettled: () => {}
	});

	return getInviteeListMutation;
};
