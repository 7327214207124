/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { InView } from 'react-intersection-observer';
import { isDev } from '@/config';
import cx from 'classnames';
import { useRootStore } from '@/stores';
import { copyToClipboard, getRandomItemFromArray } from '@/utils';
import { handleResponse } from '@/utils';
import './ChatMainArea.scss';

// 子组件
import { Markdown } from '@/components/MarkDown/MarkDown';
import LoadingDots from '@/components/LoadingDots/LoadingDots';

// 图片
import copyIcon from '@/imgs/icon/button_copy-black.svg';
import ChatGPT_Avatar from '@/imgs/icon/avatar_gpt.svg';

// API
import { usePullChatContentApi, useSaveUserConfigStatusApi, useGetWxConfigApi } from '@/api';

const ChatMainArea = () => {
	const { ChatStore, UserInfoStore, WeChatStore } = useRootStore();
	const { sessionId } = useParams();
	const [reFetchCount, setReFetchCount] = useState(0);
	const [isAvatarError, setIsAvatarError] = useState(false);
	const bottomRef = useRef(null);
	const navigate = useNavigate();

	// console.log('ChatMainArea: sessionId', sessionId);

	const pullChatContentMutation = usePullChatContentApi();
	const saveUserConfigStatusMutation = useSaveUserConfigStatusApi();
	const getWxConfigMutation = useGetWxConfigApi();
	const { isLoading: isPullingChatContent } = pullChatContentMutation;

	useLayoutEffect(() => {
		bottomRef.current?.scrollIntoView({ behavior: 'auto' });
	}, [ChatStore.messages.length, ChatStore.scrollToBottomTrigger]);

	useEffect(() => {
		if (!sessionId) {
			return;
		}
		const paramObj = {
			sessionId,
			firstChatRecordId: ChatStore.pullContent?.firstChatRecordId ?? ''
		};
		// console.log('UserInfoStore.token', UserInfoStore.token);
		UserInfoStore.token && pullChatContentMutation.mutate(paramObj);
	}, [sessionId, UserInfoStore.token]);

	const fetchWxConfig = async () => {
		const urlInIos = window.localStorage.getItem('scanUrl');
		const paramObj = {
			url: /(Android)/i.test(navigator.userAgent)
				? encodeURIComponent(window.location.href.split('#')[0])
				: encodeURIComponent(urlInIos)
		};
		await getWxConfigMutation.mutate(paramObj, {
			onSuccess: async resData => {
				const { data } = resData?.data;

				const result = handleResponse(resData);

				if (result === 'success') {
					if (data?.appId) {
						WeChatStore.setWxConfig(data);
						return;
					} else {
						if (reFetchCount < 10) {
							setReFetchCount(reFetchCount => reFetchCount + 1);
							await fetchWxConfig();
						} else {
							console.log('请求超时...');
							return;
						}
					}
				}
			},
			onError: err => {
				console.log('fetchWxConfig error', err);
				WeChatStore.setWxConfig({});
			}
		});
	};

	useEffect(() => {
		if (isDev) {
			return;
		}
		const fetchData = async () => {
			const urlInIos = window.localStorage.getItem('scanUrl');
			const paramObj = {
				url: /(Android)/i.test(navigator.userAgent)
					? encodeURIComponent(window.location.href.split('#')[0])
					: encodeURIComponent(urlInIos)
			};
			await getWxConfigMutation.mutate(paramObj, {
				onSuccess: async resData => {
					console.log('fetchData: res', resData);

					const { data } = resData?.data;

					const result = handleResponse(resData);

					if (result === 'success') {
						if (data?.appId && !WeChatStore.WxConfig.appId) {
							WeChatStore.setWxConfig(data);
							return;
						} else {
							if (reFetchCount < 10) {
								console.log('正在尝试第' + (reFetchCount + 1) + '次请求...');
								setReFetchCount(reFetchCount => reFetchCount + 1);
								await fetchWxConfig();
							} else {
								console.log('请求超时...');
								return;
							}
						}
					} else {
						if (reFetchCount < 10) {
							console.log('正在尝试第' + (reFetchCount + 1) + '次请求...');
							setReFetchCount(reFetchCount => reFetchCount + 1);
							await fetchWxConfig();
						} else {
							console.log('请求超时...');
							return;
						}
					}
				},
				onError: err => {
					console.log('fetchWxConfig error', err);
					WeChatStore.setWxConfig({});
				}
			});
		};
		fetchData();
	}, [sessionId, UserInfoStore.token]);

	useEffect(() => {
		return () => {
			ChatStore.setPullContent({});
			ChatStore.setMessages([]);
			ChatStore.setIsFirstTime(true);
		};
	}, []);

	const avatarError = () => {
		setIsAvatarError(true);
	};

	const toUpdate = () => {
		const serviceCenterRoute = '/service-center';
		navigate(serviceCenterRoute);
	};

	const toMine = () => {
		const mineRoute = '/mine';
		navigate(mineRoute);
	};

	const helpMeToAskAQuestion = () => {
		if (!UserInfoStore.isLogin) {
			WeChatStore.wxLogin();
			return;
		}

		const { recommendQuestions = [] } = ChatStore.globalConfig;
		const randomQuestion = getRandomItemFromArray(recommendQuestions);
		ChatStore.setInputValue(randomQuestion);
	};

	const noNeedToRemindMe = () => {
		const params = {
			type: 1,
			status: UserInfoStore.notRemindStatus ? 0 : 1
		};
		UserInfoStore.token && saveUserConfigStatusMutation.mutate(params);
	};

	const inViewChange = inView => {
		// console.log([
		// 	inView,
		// 	ChatStore.shouldPullMoreContent,
		// 	!isPullingChatContent,
		// 	UserInfoStore.token,
		// 	!ChatStore.isFirstTime
		// ]);

		// 进入视野 且	需要拉取更多内容 且	没有正在拉取内容 且 有token 且不是首次
		const pullContentCondition =
			inView &&
			ChatStore.shouldPullMoreContent &&
			!isPullingChatContent &&
			UserInfoStore.token &&
			!ChatStore.isFirstTime;

		if (pullContentCondition) {
			const paramObj = {
				sessionId,
				firstChatRecordId: ChatStore.pullContent?.firstChatRecordId ?? ''
			};
			pullChatContentMutation.mutate(paramObj);
		}
	};

	const chatMainAreaClassnames = cx('chat-main-area', {
		isCareerPickerHide: !ChatStore.isCareerPickerShow
	});

	const chatMainListClassnames = cx('chat-main-list', {
		isCareerPickerFoldOrHide:
			ChatStore.isCareerPickerFold ||
			!ChatStore.isCareerPickerShow ||
			ChatStore.isInputFocus ||
			UserInfoStore.notShowCareerStatus
	});

	return (
		<section className={chatMainAreaClassnames}>
			{isPullingChatContent ? (
				<div className={'chat-main-area-loading'}>
					<LoadingDots />
				</div>
			) : (
				<ul className={chatMainListClassnames}>
					<InView
						as="li"
						className={'chat-main-list-item-for-load-more'}
						onChange={(inView, entry) => {
							inViewChange(inView, entry);
						}}
					></InView>
					{ChatStore.messages.map(message => {
						const isUser = message.role === 'user';
						const isSystem = message.role === 'system';
						const isAI = message.role === 'assistant';

						const wrapperClassnames = cx('chat-main-list-item', {
							isUser,
							isAI,
							isSystem
						});

						const chatInfoClassnames = cx('chat-avatar-info', {
							isUser,
							isAI,
							isSystem
						});

						const chatContentClassnames = cx('chat-content', {
							isUser,
							isAI,
							isSystem
						});

						return (
							<li className={wrapperClassnames} key={message?.chatRecordId}>
								{isSystem && (
									<div className="chat-assistant-info">
										<img src={ChatGPT_Avatar} alt="" className="chatgpt-avatar" />
										<div className="chat-date">{message?.ctime ?? ''}</div>
									</div>
								)}
								{isAI && (
									<div className="chat-assistant-info">
										<img src={ChatGPT_Avatar} alt="" className="chatgpt-avatar" />
										<div className="chat-date">
											{message.content.length === 0 ? '正在回复...' : message?.ctime ?? ''}
										</div>
										<div
											onClick={() => {
												const isCareerPickerFoldOrHide =
													ChatStore.isCareerPickerFold ||
													!ChatStore.isCareerPickerShow ||
													ChatStore.isInputFocus;
												copyToClipboard(message?.content ?? '', isCareerPickerFoldOrHide);
											}}
											className="copy-content-icon-wrapper"
										>
											{message.content.length !== 0 ? (
												<img className="copy-content-icon" src={copyIcon} alt="copy" />
											) : null}
										</div>
									</div>
								)}
								{isUser && (
									<div className={chatInfoClassnames}>
										<div className="chat-date">{message?.ctime ?? ''}</div>
										{isAvatarError ? (
											<div className="iconfont icon-user_avatar default-avatar"></div>
										) : (
											<img
												src={
													message.avatarUrl ? message.avatarUrl : './to-trigger-on-error.png'
												}
												alt="user-avatar"
												className="user-img"
												onError={() => {
													avatarError();
												}}
											/>
										)}
									</div>
								)}

								<div className={chatContentClassnames}>
									<Markdown content={message.content} />

									{isSystem && (
										<div className="chat-assistant-buttons">
											<div
												className="chat-assistant-left-button"
												onClick={() => {
													helpMeToAskAQuestion();
												}}
											>
												{'帮我问个问题'}
											</div>
											<div
												className="chat-assistant-right-button"
												onClick={() => {
													noNeedToRemindMe();
												}}
											>
												{UserInfoStore.notRemindStatus ? '不再提醒' : '已设置不再提醒'}
											</div>
										</div>
									)}
								</div>
							</li>
						);
					})}
					{/* 免费次数已用尽提示 */}
					{ChatStore.isFreeTimesUsedUp && (
						<li className="free-times-used-up">
							<div className="free-times-used-up-title-text">{ChatStore.freeTimesUsedUpTitleText}</div>
							<div className="free-time-usd-up-buttons">
								<div
									className="to-update-btn"
									onClick={() => {
										toUpdate();
									}}
								>
									{'去升级'}
								</div>
								<div
									className="to-recharge-btn"
									onClick={() => {
										toMine();
									}}
								>
									{'邀请好友领 7 天会员'}
								</div>
							</div>
						</li>
					)}
					<li ref={bottomRef} className="bottom-of-message-list"></li>
				</ul>
			)}
		</section>
	);
};

ChatMainArea.displayName = 'ChatMainArea';

export default observer(ChatMainArea);
