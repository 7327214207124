import { makeAutoObservable } from 'mobx';

export class RouterStore {
	storeNavigate = null;
	storeLocation = null;

	constructor(rootStore) {
		makeAutoObservable(this, {
			rootStore: false
		});
		this.rootStore = rootStore;
	}

	/* =============================== 计算属性 ================================ */
	get currentRoute() {
		return this.storeLocation?.pathname;
	}

	/* =============================== 方法 ================================ */
	setStoreNavigate(navigate) {
		this.storeNavigate = navigate;
	}
	setStoreLocation(location) {
		this.storeLocation = location;
	}
}
