import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRootStore } from '@/stores';
import { WE_CHAT_CONFIG } from '@/config';
import { handleResponse } from '@/utils';

export const useGetWxConfigApi = () => {
	const { UserInfoStore, WeChatStore } = useRootStore();

	const getWxConfig = ({ url }) => {
		const params = {
			url
		};
		return axios.post(WE_CHAT_CONFIG, params, {
			headers: {
				token: UserInfoStore.token
			}
		});
	};

	const getWxConfigMutation = useMutation(getWxConfig, {
		onSuccess: resData => {
			const { data } = resData?.data;

			const result = handleResponse(resData);

			if (result === 'success') {
				WeChatStore.setWxConfig(data);
			}
		},
		onError: err => {
			console.error('useGetWxConfigApi', err);
		},
		onSettled: () => {}
	});

	return getWxConfigMutation;
};
